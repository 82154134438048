import "./style.css";
import React, { useState, useEffect } from "react";
import Info from "../home/info";
import { useLocation } from "react-router-dom";
export default function RawMaterial({ setSubFooter }) {
  let [currentView, setCurrentView] = useState("tmtRebears");
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    let url = window.location.href.split("=")[1];
    setCurrentView(url ? url : "tmtRebears");
    setSubFooter(false);
  }, [location]);

  let data = {
    aluminum: {
      para: [
        `One of the largest integrated primary sources of aluminium are in India. We have dedicated sources to find the best extrusions from various aluminium companies at the most reliable and reasonable prices.
        Our strength in aluminium products differentiat`,
        `Our strength in aluminium products differentiates us from our competitors. A significant portion of Rawmart’s sales comes from value-added products. Rawmart caters to numerous markets and provides for all industrial and infrastructural requirements.`,
        `Our finished products include primary aluminium in the form of ingots, billets and wire rods and value-added products such as rolled products, extrusions and foils.`,
        `Our team is well equipped to compare conversion costs and quality of the product supplied; a vital service that makes Rawmart a leading supplier in the extrusions industry in India. We also offer a wide range of alloys including hard alloys and special alloys for defence and space sectors.`,
      ],
      pPara: "List of Aluminium Products we Supply: ",
      listOfProduces: [
        "Aluminium Angles.",
        "Aluminium Channels.",
        "Aluminium Flat Bars.",
        "Aluminium Hexagonal Bars.",
        "Aluminium Rectangular Tubes.",
        "Aluminium Round Tubes.",
        "Aluminium Square Tubes.",
        "Aluminium IPS Tubes.",
        "Aluminium Rods.",
        "Aluminium Alloy Ingots.",
        "Aluminium T-Sections.",
        "Aluminium Heat Sinked",
        "Aluminium Machine Components",
      ],
      showBradInSlider: 2,
      brandLogo: [
        "assets/images/brandlogo/hindalco.jpg",
        "assets/images/brandlogo/nalco.jpg",
      ],

      images: [
        "assets/images/products/al-img1.jpg",
        "assets/images/products/al-img2.jpg",
        "assets/images/products/al-img3.jpg",
        "assets/images/products/al-img4.jpg",
        "assets/images/products/al-img5.jpg",
        "assets/images/products/al-img6.jpg",
        "assets/images/products/al-img7.jpg",
        "assets/images/products/al-img8.jpg",
        "assets/images/products/al-img9.jpg",
      ],
    },

    stainlesssteel: {
      para: [
        `Stainless Steel is widely known in the industrial sector as the unmatched ‘green material’ for all the right reasons. With actual recovery rate touching 100%, it is completely recyclable, corrosion resistant, fire resistant, super sturdy, aesthetic and versatile. Stainless Steel is generally seen as a family of chromium-containing alloys.`,
        `Stainless Steel plays a major role in the hygienic preparation or storage of almost everything we eat or drink. All modern vehicles boast stainless steel components in critical areas. Stainless steel is used increasingly in a large scale in Railways, Pharmaceuticals industry, FMCG Food Processing, Aeronautics and in several other regions.`,
        `Rawmart supplies a wide range of hot rolled/cold rolled coils and cut lengths (sheets) in austenitic, ferritic and martensitic grades of stainless-steel conforming to ASTM, BIS and various other specifications in a variety of sizes and finishes of several grades such as 200, 300 and 400.`,
      ],
      pPara: "List of Stainless-steel products we supply :",
      listOfProduces: [
        "HR Coils / Sheets.",
        "CR Coils / Sheets.",
        "Pipes.",
        "Tubes.",
        "Flats.",
        "Rounds.",
      ],
      showBradInSlider: 2,
      brandLogo: [
        "assets/images/brandlogo/jsl.png",
        "assets/images/brandlogo/Sail.png",
      ],
      images: [
        "assets/images/products/ss-img1.jpg",
        "assets/images/products/ss-img2.jpg",
        "assets/images/products/ss-img3.jpg",
        "assets/images/products/ss-img4.jpg",
        "assets/images/products/ss-img5.jpg",
        "assets/images/products/ss-img6.jpg",
        "assets/images/products/ss-img7.jpg",
        "assets/images/products/ss-img8.jpg",
      ],
    },

    mildsteel: {
      para: [
        `Mild steel, also known as low carbon steel, utilizes only a minute amount of carbon. The percentage of carbon may vary from 0.05% to 0.25%. The insignificant number of alloying elements helps mild steel to be affordable relatively when done in comparison with other steels. The machinability, affordability, and weldability are making it a popular steel choice for users.
`,
        `High affordability topped with outstanding properties gives mild steel an inalienable place in the construction industry. Buildings, bridges, pipelines, gates, fencing etc. are only a few to mention. It is also used in many industries like shipbuilding, galvanizing pots, industrial flooring, etc. `,
        `Rawmart as a reliable supplier is offering the high quality of special mild steel products to the buyers with different specifications that are available in different material grades, width, thickness, length, etc.
`,
        `Rawmart deals in both Primary and Secondary Brands.`,
      ],
      pPara: "List of Mild Steel Products which we supply:",
      headingTitle1: "Structural Steel Products",
      listOfProduces: [
        "Angle",
        "Channel",
        "Flats",
        "Round Bars",
        "Square Bars",
        "Beams",
        "Wire Rods",
        "Tubes",
        "Pipe",
      ],
      headingTitle2: "TMT REBARS",

      listOfProduces2: [
        "FE500",
        "FE550",
        "FE500D",
        "FE550D",
        "Epoxy Coated",
        "CRS TMT",
      ],
      headingTitle3: "Hot Rolled Products",
      listOfProduces3: [
        "HR Coils",
        "HR Sheets",
        "HR Plates",
        "HRPO Sheets / Coils",
        "HRSPO Sheets / Coils",
      ],
      headingTitle4: "Cold Rolled Products",
      listOfProduces4: ["CR Coils ", "CR Sheets"],
      showBradInSlider: 2,
      brandLogo: [
        "assets/images/brandlogo/jsw.png",
        "assets/images/brandlogo/tatasteel.png",
        "assets/images/brandlogo/neosteel.jpeg",
        "assets/images/brandlogo/tatatiscon.png",
        "assets/images/brandlogo/Sail.png",
        "assets/images/brandlogo/rinl.png",
        "assets/images/brandlogo/essar.jpg",
        "assets/images/brandlogo/uma.png",
        "assets/images/brandlogo/rajuri.jpeg",
        "assets/images/brandlogo/kamdhenu.jpg",
        "assets/images/brandlogo/polad.jpg",
        "assets/images/brandlogo/gkispat.png",
        "assets/images/brandlogo/balaji.png",
        "assets/images/brandlogo/posco.png",
        "assets/images/brandlogo/uttam.png",
        "assets/images/brandlogo/jspl.png",
      ],
      images: [
        "assets/images/products/ms-img1.jpg",
        "assets/images/products/ms-img2.jpg",
        "assets/images/products/ms-img3.jpg",
        "assets/images/products/ms-img4.jpg",
        "assets/images/products/ms-img5.jpg",
        "assets/images/products/ms-img6.jpg",
        "assets/images/products/ms-img7.jpg",
        "assets/images/products/ms-img8.jpg",
        "assets/images/products/ms-img9.jpg",
        "assets/images/products/ms-img10.jpg",
        "assets/images/products/ms-img11.jpg",
      ],
    },

    polymer: {
      para: [
        `Engineering polymers are types of plastic materials. However, unlike commodity plastics they have special properties like high dimensional stability, good strength, heat and chemical resistance, which makes them a widely used substitute for metals across industries.`,
        `Consumer Durables and White Goods/ Appliances market, automotive and transportation industries are the biggest end-users of Engineering Plastics.`,
        `Well established applications of polymers in construction include areas such as flooring, windows, cladding, rainwater, pipes, membranes, seals, glazing, insulation and signage. With thousands of commercially available polymers, new applications are continuously emerging.`,
        ``,
      ],
      pPara:
        "Rawmart supplies a variety of Engineering Polymers at most competitive rates across the country:",
      listOfProduces: [
        "Acrylonitrile butadienestyrene(ABS)",
        "Expanded Polystyrene",
        "High Impact Polystyrene",
        "Nylon 66",
        "Nylon-6",
        "Polymethyl methacrylate(PMMA)",
        "Polycarbonate",
        "General Purpose Polystyrene(GPPS)",
      ],
      images: [
        "assets/images/products/pl-img1.jpg",
        "assets/images/products/pl-img2.jpg",
        "assets/images/products/pl-img3.jpg",
        "assets/images/products/pl-img4.jpg",
      ],
    },

    otherstel: {
      last: true,

      products: [
        {
          productName: "Galvanized Products: ",
          para: [
            `Galvanized Steel are basically sheets which have been coated with zinc. This includes a hot dip galvanized and electro galvanized steel sheets. The zinc coating provides a continuous barrier which does not allow moisture and oxygen to reach the steel.
`,
            `The applications of Galvanised Steel are numerous, especially in fabrication and manufacturing environments. Construction workers mostly use Galvanised Coils for Roofing applications. They are also used as sheet metal brakes in buildings. 
`,
            `The biggest use of GI Sheets is for construction projects in homes, offices, and factories. They resist corrosion, heat, and are easily installable, making them best suited for roofing. They are also used in the production of corrugated panels, drywall panel profiles, ventilation systems, etc.
`,
          ],
          showBradInSlider: 3,
          brandLogo: [
            "assets/images/brandlogo/uttam.png",
            "assets/images/brandlogo/jsw.png",
            "assets/images/brandlogo/amns.png",
            "assets/images/brandlogo/tatabsl.png",
          ],
        },

        {
          productName: "PPGL",
          para: [
            `PPGL Sheets / Coils are prepared by combining the strength of steel with anti-corrosive capabilities of a metal coating like Zinc or Aluminium. The resultant product then has to be coated with organic substances.`,
            `It is exceptionally resistant to weather and environmental degradations and can be punched, pressed, roll formed or joined in any way possible per industrial requirement.`,
            `The Usage of PPGL Coil spans the Construction and Home Appliance Manufacturing Industries. PPGL is used as a great alternative for wood owing to benefits such as avoidance of discoloration, and weather tolerance. Automobile Manufacturers and Producers employ PPGL Coil. Coal Mines also see sufficient use of this product. Other Industries include Light Manufacturing, Medicine Industry, Food Industry and Furniture Manufacturing.`,
            `PPGL Sheets see extensive use in Building and Roofing Applications. They are also used in making certain internal automotive parts and appliances. Computer cases, wooden panels, gutters, pipe, furniture, outdoor cabinetry also need PPGL Sheets for manufacture. Architects, Building Owners, and Interior Decorators are mostly in need of PPGL sheets.
            Rawmart deals in almost all dimensions in PPGL Sheets/Coils ranging from 0.25mm to 0.60mm.`,
          ],
          pPara: "Galvanized products include :",
          listOfProduces: ["GI Coils", "GI Sheets"],
          showBradInSlider: 2,
          brandLogo: [
            "assets/images/brandlogo/tatadurashine.png",
            "assets/images/brandlogo/jswcolor.png",
          ],
        },

        {
          productName: "PPGI",
          para: [
            `PPGI is pre-painted galvanised iron, also known as pre-coated steel, coil coated steel, color coated steel etc., typically with a hot dip zinc coated steel substrate. ... PPGI refers to factory pre-painted zinc coated steel, where the steel is painted before forming, as opposed to post painting which occurs after forming.`,
            `PPGI Coils are used mainly in the Automobile and Construction industry. It is commonly used for making building material such as roofing sheets, roofing tiles, sandwich panels etc. The Transportation, Manufacturing Industry, Light Industry, manufacture of office furniture, manufacture of household electrical appliances etc. also make use of PPGI Coils.`,
            `The biggest use of PPGI Steel Sheets is as Roofing Sheets for Buildings and in Construction Projects. Other places where they are used are in Side Walls, Partitions, Panels, Valley Gutters, Louvers, False Ceilings, Partition Walls and Ducks, Rolling Shutters, Highway Bumpers, Slotted Angles, and Paint-Coated products.`,
          ],

          showBradInSlider: 2,
          brandLogo: [
            "assets/images/brandlogo/amns.png",
            "assets/images/brandlogo/uttam.png",
            "assets/images/brandlogo/asian.png",
          ],
        },

        {
          productName: "Chequered Plate",
          para: [
            `Chequer plate also called chequered plate is a plate with a regular pattern of projections on one side, showing diamond or other shapes, with the reverse side being smooth. It can be in the form of tear drop mild steel chequered plates, diamond shaped mild steel chequered plates or embossed mild steel plates.`,
            `Steel chequered plates are extensively used as floor coverings in marine, stairways, transportation equipment and for general structural purposes.`,
          ],

          showBradInSlider: 2,
          brandLogo: [
            "assets/images/brandlogo/Sail.png",
            "assets/images/brandlogo/jsw.png",
            "assets/images/brandlogo/jspl.png",
            "assets/images/brandlogo/tatasteel.png",
          ],
        },

        {
          productName: "BQ Plate",
          para: [
            `BQ PLATES the boiler quality and pressure vessel steel plates are primarily intended for use in welded pressure vessels where notch toughness is important. These grades cover a range of tensile strengths from 450 - 620 MPa and this versatility explains much of the specification’s popularity.`,
            `Boiler steel plate is one of the most important materials in boiler manufacturing. It mainly refers to hot-rolled special carbon steel and low alloy used for manufacturing important parts such as boiler shell, drum, header cover, and hanger. Heat resistant steel medium and thick steel plate material.`,
          ],
        },

        {
          productName: "DI Pipes",
          para: [
            `Ductile iron pipe (DI pipe) is created from ferric scrap metal typically containing greater than 98 percent recycled content.`,
            `Back in 1948, it was observed that addition of magnesium in the molten pig iron could significantly alter the graphite structure in Cast Iron. In the initial microstructure of grey cast iron, graphite was in the form of flakes but assumed a spheroidal/nodular shape after the addition of Magnesium. The mechanical property of ductile iron as compared to grey cast iron show superior tensile strength, yield strength, ductility and impact resistance. The use of ductile iron has grown rapid rate over the past 50 years. The much-improved properties were recognised to be of particular benefit to the centrifugal pipe industry and most of the pipe production is now in Ductile Iron.`,
            `The range comprises of Socket and Spigot end Ductile Iron Pipes suitable for Push-on Joints as per IS 8329, BS EN 545, BS EN 598 & ISO 253`,
          ],

          list: true,
          listItem: [
            "Water Transmission and Distribution",
            "Gravity Sewers and Pressure Mains",
            "Fire Protection Systems",
          ],
        },
      ],

      images: [
        "assets/images/products/ot-img1.jpg",
        "assets/images/products/ot-img2.jpg",
        "assets/images/products/ot-img3.jpg",
        "assets/images/products/ot-img4.jpg",
        "assets/images/products/ot-img5.jpg",
        "assets/images/products/ot-img6.jpg",
        "assets/images/products/ot-img7.jpg",
        "assets/images/products/ot-img8.jpg",
        "assets/images/products/ot-img9.jpg",
        "assets/images/products/ot-img10.jpg",
      ],
    },

    tmtRebears: {
      para: [
        `One of the largest integrated primary sources of aluminium are in India. We have dedicated sources to find the best extrusions from various aluminium companies at the most reliable and reasonable prices.
        Our strength in aluminium products differentiat`,
        `Our strength in aluminium products differentiates us from our competitors. A significant portion of Rawmart’s sales comes from value-added products. Rawmart caters to numerous markets and provides for all industrial and infrastructural requirements.`,
        `Our finished products include primary aluminium in the form of ingots, billets and wire rods and value-added products such as rolled products, extrusions and foils.`,
        `Our team is well equipped to compare conversion costs and quality of the product supplied; a vital service that makes Rawmart a leading supplier in the extrusions industry in India. We also offer a wide range of alloys including hard alloys and special alloys for defence and space sectors.`,
      ],
      pPara: "List of Aluminium Products we Supply: ",
      listOfProduces: [
        "Aluminium Angles.",
        "Aluminium Channels.",
        "Aluminium Flat Bars.",
        "Aluminium Hexagonal Bars.",
        "Aluminium Rectangular Tubes.",
        "Aluminium Round Tubes.",
        "Aluminium Square Tubes.",
        "Aluminium IPS Tubes.",
        "Aluminium Rods.",
        "Aluminium Alloy Ingots.",
        "Aluminium T-Sections.",
        "Aluminium Heat Sinked",
        "Aluminium Machine Components",
      ],
      showBradInSlider: 2,
      brandLogo: [
        "assets/images/brandlogo/essar.jpg",
        "assets/images/brandlogo/goel.png",
        "assets/images/brandlogo/jsw2.png",
        "assets/images/brandlogo/polaad.jpg",
        "assets/images/brandlogo/rajuri.jpg",
        "assets/images/brandlogo/rinl2.jpg",
        "assets/images/brandlogo/sail2.jpg",
        "assets/images/brandlogo/tata.png",
        "assets/images/brandlogo/uma.jpg",
        "assets/images/brandlogo/jspl2.png",
      ],

      images: [
        "assets/images/products/tmt1.jpg",
        "assets/images/products/tmt2.jpg",
        "assets/images/products/tmt7.jpg",
        "assets/images/products/tmt4.jpg",
        "assets/images/products/tmt5.jpg",
        "assets/images/products/tmt6.jpg",
      ],
    },
  };
  return (
    <div className="">
      <div className="bg-img">
        <h2 className="headingH2">Our Procurement Consulting</h2>
      </div>
      <section className="pad100-top-bottom padding-0-res">
        <div className="container">
          <div className="row">
            {/* <Menu view={setCurrentView} /> */}
            <Info info={data[currentView]} />
          </div>
        </div>
      </section>
    </div>
  );
}
