import FeatureSections from "../home/featureSection";
import { useEffect } from "react";
export default function ProcurementServices() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<section className="home4 recent-project-section pad100-top projectsec1">
			<div className="container" style={{marginTop:"30px"}}>
				<h3
					className="white-color h3Heading heading-h3"
					style={{ marginBottom: "0px" }}
				>
					Procurement
					<span className="clr-blue"> Consulting</span>
				</h3>
				{/* <p
          style={{
            marginBottom: "20px",
            fontSize: "20px",
            textAlign: "center",
          }}
        >
          Providing expertise to assist you in procurement function
        </p> */}
				<p
					style={{
						fontSize: "22px",
						marginBottom: "20px",
						textAlign: "center",
						marginTop: "10px",
					}}
				>
					Providing expertise to assist you in procurement function
				</p>
				<div className="procurementServices__img" style={tenderManagement}>
					<img
						src="\assets\images\banners\procurement consulting 3.png"
						alt="procurementServices__img"
						// style={{ tenderManagementImg }}
						style={{ objectFit: "contain", height: "100%", width: "100%" }}
					/>
				</div>
				<p
					style={{
						fontSize: "16px",
						marginBottom: "20px",
						textAlign: "justify",
					}}
				>
					As a result of global market conditions and the requirement for
					businesses to reduce and manage costs, the demand for expert
					procurement service SMEs has altogether expanded. Many top firms
					within the world have as of now signed up with procurement services
					providers while many are still trying to find a way to tap into this
					service.
				</p>
				<p
					style={{
						fontSize: "16px",
						marginBottom: "20px",
						textAlign: "justify",
					}}
				>
					There are several reasons why your company should opt for rawmart
					Procurement services. With our expertise you will save a massive
					amount of money; since for most organisations, it is very costly to
					set up and maintain a good procurement department. Using our
					procurement service, your business will cost less expense and deliver
					better procurement services than internal procurement departments can
					offer.
				</p>

				<p
					style={{
						fontSize: "16px",
						marginBottom: "20px",
						textAlign: "justify",
					}}
				>
					Procurement Services at Rawmart incorporate expert procurement
					consulting, instinctive strategic sourcing and advertising quick
					data-backed advertisement intelligence. We have promised to
					persistently progress our services embracing more current and more
					refined approaches to form raw material procurement relaxed, agreeable
					and profitable for SMEs. We further examine the leading market persona
					for you within the most reasonable way. We calculate the leading rates
					for you by analysing costs right from ‘procurement to delivery of
					material’. With us, you'll be able to benefit from comprehensive
					rate-comparison between diverse raw material sources that can assist
					you in deciding upon the perfect deal.
				</p>

				<p
					style={{
						fontSize: "16px",
						marginBottom: "20px",
						textAlign: "justify",
					}}
				>
					As Procurement continues to evolve, both huge and small businesses
					discover themselves in need of more robust strategic sourcing
					operations, and the resources to create and keep up Procurement
					functions of the appropriate measure, structure, and development.
					Rawmart Procurement Services incorporates a group of profoundly
					experienced procurement consulting services experts, instinctive
					strategic sourcing innovations, and data-backed market insights.
					Together, they provide the specialised prerequisites to help drive the
					evolution of your Procurement from an absolutely strategic work to an
					innovative, nuanced, and strategic unit.
				</p>
				{/* <p
          style={{
            fontSize: "16px",
            marginBottom: "20px",
            textAlign: "justify",
          }}
        >
          Rawmart Procedure leverages this advantage in our work with boards,
          CEOs, and executives to create benefits for shareholders and
          stakeholders by characterising and answering their most key business
          questions on subjects like growth, profitability, technology-driven
          transformation, M&A, working models, and sustainability.
        </p>
        <p
          style={{
            fontSize: "22px",
            marginBottom: "20px",
            textAlign: "center",
          }}
        >
          Export Consulting
        </p>
        <p
          style={{
            fontSize: "16px",
            marginBottom: "20px",
            textAlign: "justify",
          }}
        >
          The importing and exporting process can be difficult to those who are
          new with the documentation, shipping procedures and different parts
          included within the development of goods from one nation to another.
          Indeed for the prepared importer/exporter, doing it all alone can be a
          hassle.
        </p>
        <p
          style={{
            fontSize: "16px",
            marginBottom: "20px",
            textAlign: "justify",
          }}
        >
          Our import/export specialists have worked in each aspect of the trade,
          and not as it were can we offer assistance to advise importers and
          exporters, but we moreover give customs brokerage and documentation
          services. A small piece of advice can go a long way to help you get
          your international business up and running with less bumps in the
          street.
        </p>
        <p
          style={{
            fontSize: "16px",
            marginBottom: "20px",
            textAlign: "justify",
          }}
        >
          Rawmart could be a multi-disciplinary consultancy association locked
          in in different consultancy lines particularly the field of export
          Incentives.Our involvement within the pertinent businesses, gives us a
          business-oriented approach. Coupled with our expertise on the matter
          of Foreign Trade Policy, and custom Strategies & related issues, over
          diverse segments of businesses, it makes a difference as we best
          optimise the benefits for our clients and discover innovative
          solutions.
        </p>
        <p
          style={{
            fontSize: "16px",
            marginBottom: "20px",
            textAlign: "justify",
          }}
        >
          Rawmart Specialises in making a difference in businesses growth
          similar to yours in profitably growing through worldwide markets. We
          offer expert support and guidance within the areas you need to make
          sure you're completely prepared and have a winning technique in place
          for an effective export journey.
        </p>
        <p
          style={{
            fontSize: "16px",
            marginBottom: "20px",
            textAlign: "justify",
          }}
        >
          Using our tried and tested techniques, we work with you to evaluate
          your level of export status Furthermore, we plan and implement a trade
          strategy that actually works and delivers results. We help you to
          maintain a strategic distance from the expensive mistakes, stop you
          going down the blind alleys or taking the wrong turns.
        </p> */}
			</div>
			<FeatureSections setMargin={true} />
		</section>
	);
}

const tenderManagement = {
	height: "400px",
	widht: "400px",
	marginBottom: "10px",
};

const tenderManagementImg = {
	height: "100%",
	widht: "100%",
	objectContent: "fit",
};
