import { useEffect } from "react";
export default function RawmaterialFinancingSupplyChainFinancing() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<section className="home4 recent-project-section pad100-top projectsec1">
			<div className="container" style={{marginTop:"30px"}}>
				<h3
					className="white-color h3Heading heading-h3"
					style={{ marginBottom: "0px" }}
				>
					Raw Material Financing / Supply Chain{" "}
					<span className="clr-blue">Financing</span>
				</h3>
				{/* <p
          style={{
            marginBottom: "20px",
            fontSize: "20px",
            textAlign: "center",
          }}
        >
          Providing expertise to assist you in procurement function
        </p> */}
				<p
					style={{
						fontSize: "22px",
						marginBottom: "20px",
						textAlign: "center",
						marginTop: "10px",
					}}
				>
					Build partnerships with your suppliers and distributors with our
					financing solutions.
				</p>

				<div className="rawmaterial__img" style={tenderManagement}>
					<img
						src="\assets\images\banners\fianacing pic.png"
						alt="rawamaterial__img"
						// style={{ tenderManagementImg }}
						style={{ objectFit: "contain", height: "100%", width: "100%" }}
					/>
				</div>
				<p
					style={{
						fontSize: "16px",
						marginBottom: "20px",
						textAlign: "justify",
					}}
				>
					Most SMEs are facing working capital challenges in managing their
					supply chain. SMEs need liquidity early in the procurement cycle and
					need funding to grow their businesses. By giving partners access to
					affordable working capital, we are helping to strengthen the
					relationships between suppliers and distributors which will help
					improve your supply chain performance.
				</p>

				<p
					style={{
						fontSize: "16px",
						marginBottom: "20px",
						textAlign: "justify",
					}}
				>
					We have developed a financing solution that can cover the end-to-end
					streams of supply chains. By empowering businesses to support
					suppliers from the accommodation of the Purchase Order to the
					acknowledgment of the deals invoice, suppliers can get to
					cost-effective financing prior within the procurement process.
				</p>

				<p
					style={{
						fontSize: "16px",
						marginBottom: "20px",
						textAlign: "justify",
					}}
				>
					This will give suppliers the liquidity to buy the raw materials to
					fulfil orders. They now do not have to be compelled to depend on
					conventional two-sided financing, which could be inaccessible, as well
					costly, or collateral-heavy for them; that's why we are here – to
					supply a reliable choice to suppliers to access liquidity.
				</p>

				<p style={{ fontSize: "16px", marginBottom: "20px" }}>
					Raw material Finance is a particular solution within the ever-growing
					Supply Chain Financing space. This brings consistent and guaranteed
					funds to the whole ecosystem of buyers of large scale manufacturers,
					which are typically small and medium-sized enterprises.
				</p>
				<p style={{ fontSize: "16px", marginBottom: "20px" }}>
					By providing competitive financing to the supply chain, suppliers will
					be able to grow their business. When their business grows, this will
					help the manufacturers to gain market share and improve your
					competitive value.
				</p>
				{/*
        <p style={{ fontSize: "18px", marginBottom: "20px" }}>
          As Procurement continues to evolve, both huge and small businesses
          discover themselves in need of more robust strategic sourcing
          operations, and the resources to create and keep up Procurement
          functions of the appropriate measure, structure, and development.
          Rawmart Procurement Services incorporates a group of profoundly
          experienced procurement consulting services experts, instinctive
          strategic sourcing innovations, and data-backed market insights.
          Together, they provide the specialised prerequisites to help drive the
          evolution of your Procurement from an absolutely strategic work to an
          innovative, nuanced, and strategic unit.
        </p> */}
			</div>
		</section>
	);
}

const tenderManagement = {
	height: "400px",
	widht: "400px",
	marginBottom: "10px",
};

const tenderManagementImg = {
	height: "100%",
	widht: "100%",
	objectContent: "fit",
};
