export function WhyRawmartBox(data) {
    
    let { title, des, des2, list, img, first } = data.data;
    let paddingValue = data.first ? "0px!important" : "60px"
    return (
        <div className="col-md-6 col-sm-6 col-xs-12 ">
            <div className="singleService">
                <div className="serviceImgArea">
                    <img src={img} alt="services" />
                </div>
                <div className="serviceContent">
                    <h5>{title}</h5>
                    <p className="paraSection2 marbtm20">{des}</p>
                    {list ? (
                        <ul className="listingFeature">
                            {list.map((i, j) => {
                                return <li key={j}>{i}</li>;
                            })}
                        </ul>
                    ) : null}
                    {des2 ? <p className="paraSection">{des2}</p> : null}
                </div>
            </div>
        </div>
    );
}