import Slider from "../slider/slider";
export default function SolutionInfo({ info1 }) {
	return (
		<div className="col-md-12 right-column">
			<div className="service-right-desc">
				{info1.last ? (
					<OtherProduct info1={info1} />
				) : (
					<DefaultComponent info1={info1} />
				)}
			</div>
		</div>	

		// <div className="row">
		// 	<div className="col-md-6 col-sm-12 ">
		// 		{
		// 			info1.map((obj)=>{
		// 				return <><h3>{obj.productName}</h3><div>{obj.para}</div></>;
		// 			})
		// 		}
		// 	</div>
		// </div>
	);
}

export const DefaultComponent = ({ info1 }) => {
	return (
		<div className="para">
			<CommonDiv info1={info1} />

			{info1.headingTitle2 ? (
				<p className="boldPara">{info1.headingTitle2}</p>
			) : null}
			{info1.listOfProduces2 ? (
				<ul>
					{info1.listOfProduces2
						? info1.listOfProduces2.map((i, j) => {
								return <li key={j}>{i}</li>;
						  })
						: null}
				</ul>
			) : null}

			{info1.headingTitle3 ? (
				<p className="boldPara">{info1.headingTitle3}</p>
			) : null}
			{info1.listOfProduces3 ? (
				<ul>
					{info1.listOfProduces3
						? info1.listOfProduces3.map((i, j) => {
								return <li key={j}>{i}</li>;
						  })
						: null}
				</ul>
			) : null}

			{info1.headingTitle4 ? (
				<p className="boldPara">{info1.headingTitle4}</p>
			) : null}
			{info1.listOfProduces4 ? (
				<ul>
					{info1.listOfProduces4
						? info1.listOfProduces4.map((i, j) => {
								return <li key={j}>{i}</li>;
						  })
						: null}
				</ul>
			) : null}
		</div>
	);
};


export const OtherProduct = ({ info1 }) => {
	return (
		<div>
			{info1.products.map((i, j) => {
				return <CommonDiv key={j} info1={i} srNo={j + 1} />;
			})}
		</div>
	);
};



export const CommonDiv = ({ info1, srNo }) => {
	return (
		<div className="para1">
			{info1.productName ? (
				<p className="boldPara">
					{srNo}. {info1.productName}
				</p>
			) : null}
			{info1.para.map((i, j) => {
				return <li key={j}>{i}</li>;
			})}

			{info1.headingTitle1 ? <p>{info1.headingTitle1}</p> : null}
			<ul>
				{info1.listOfProduces
					? info1.listOfProduces.map((i, j) => {
							return <li key={j}>{i}</li>;
					  })
					: null}
			</ul>
			{info1.list ? (
				<ul>
					{info1.listItem.map((i, j) => {
						return <li key={j}>{i}</li>;
					})}
				</ul>
			) : null}
		</div>
	);
};



