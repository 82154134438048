export default function FeatureSections({setMargin=false}){
  return (
    <div className="boxes-column" style={setMargin ? {margin:0} : null}>
      <ul>
        <li>
          <span className="boxes-icons">
            <img src="assets/images/aboutus/img3.png" alt="team-icon" />
          </span>
          <div className="boxes-desc">
            <h4>Quality Assurance</h4>
            {/* <p>
              Rawmart assures to bring only the finest quality Raw materials
              that can help you design end products par excellence. With our
              complex cost optimization policies that have been perfected over
              the years, we can make Essential Stock available to you at
              incredibly budget friendly prices.
            </p> */}
          </div>
        </li>

        <li>
          <span className="boxes-icons">
            <img src="assets/images/aboutus/img2.png" alt="team-icon" />
          </span>
          <div className="boxes-desc">
            <h4>Delivery on Time</h4>
            {/* <p>
              At Rawmart, A Promise is A Promise. We take pride in living up to
              our commitments. So that you can meet your production commitments
              and maintain smooth and hassle-free supply.
            </p> */}
          </div>
        </li>

        <li>
          <span className="boxes-icons">
            <img src="assets/images/aboutus/img1.png" alt="team-icon" />
          </span>
          <div className="boxes-desc">
            <h4>Comprehensive Support</h4>
            {/* <p>
              Raw Mart offers comprehensive online / offline support to all
              clients where we answer relevant queries and grievances (if any)
              in the shortest possible time. Our support helps customers
              maintain smooth business operations while making their experience
              pleasant and insightful.
            </p> */}
          </div>
        </li>
      </ul>
    </div>
  );
}