export default function OurProcess() {
  const {innerWidth} = window;

  return (
    <section className="pad100-top-bottom" style={{ background: "#f2f2f2" }}>
      <div className="container">
        <h3 className="white-color h3Heading heading-h3">
          Our <span className="clr-blue">Process</span>
        </h3>
      </div>
      <div className="container">
        <div className="row text-center">
          <img className="" src={`assets/images/ourprocess${innerWidth <= 400 ? '-res' : ''}.svg`} alt="svg" />
        </div>
      </div>
    </section>
  );
}