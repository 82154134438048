import Slider from "../slider/slider";
export default function DesignInfo({ info2 }) {
	return (
		<div className="col-md-12 right-column">
			<div className="service-right-desc">
				{info2.last ? (
					<OtherProduct info2={info2} />
				) : (
					<DefaultComponent info2={info2} />
				)}
			</div>
		</div>	
	);
}
export const DefaultComponent = ({ info2 }) => {
	return (
		<div className="para">
			<CommonDiv info2={info2} />

			{info2.headingTitle2 ? (
				<p className="boldPara">{info2.headingTitle2}</p>
			) : null}
			{info2.listOfProduces2 ? (
				<ul>
					{info2.listOfProduces2
						? info2.listOfProduces2.map((i, j) => {
								return <li key={j}>{i}</li>;
						  })
						: null}
				</ul>
			) : null}

			{info2.headingTitle3 ? (
				<p className="boldPara">{info2.headingTitle3}</p>
			) : null}
			{info2.listOfProduces3 ? (
				<ul>
					{info2.listOfProduces3
						? info2.listOfProduces3.map((i, j) => {
								return <li key={j}>{i}</li>;
						  })
						: null}
				</ul>
			) : null}

			{info2.headingTitle4 ? (
				<p className="boldPara">{info2.headingTitle4}</p>
			) : null}
			{info2.listOfProduces4 ? (
				<ul>
					{info2.listOfProduces4
						? info2.listOfProduces4.map((i, j) => {
								return <li key={j}>{i}</li>;
						  })
						: null}
				</ul>
			) : null}
		</div>
	);
};

export const OtherProduct = ({ info2 }) => {
	return (
		<div>
			{info2.products.map((i, j) => {
				return <CommonDiv key={j} info2={i} srNo={j + 1} />;
			})}
		</div>
	);
};


export const CommonDiv = ({ info2, srNo }) => {
	return (
		<div className="para">
			{info2.productName ? (
				<p className="boldPara">
					{srNo}. {info2.productName}
				</p>
			) : null}
			{info2.para.map((i, j) => {
				return <li key={j}>{i}</li>;
			})}

			{info2.headingTitle1 ? <p>{info2.headingTitle1}</p> : null}
			<ul>
				{info2.listOfProduces
					? info2.listOfProduces.map((i, j) => {
							return <li key={j}>{i}</li>;
					  })
					: null}
			</ul>
			{info2.list ? (
				<ul>
					{info2.listItem.map((i, j) => {
						return <li key={j}>{i}</li>;
					})}
				</ul>
			) : null}
		</div>
	);
};


