const SubSections = ({ tab }) => {
  return (
    <div className="tabSections">
      {tab === "story" ? (
        <Story />
      ) : 
      tab === "tmacTab" ? (<Management/>) : 
      tab === "approch" ? (
        <Approch />
      ) : tab === "govOrg" ? (
        <GovOrg />
      ) : tab === "railway" ? (
        <Railway />
      ) : tab === "psuCompany" ? (
        <PsuCompany />
      ) : tab === "ourServices" ? (
        <OurServices />
      ) : null}
    </div>
  );
};

export const Story = () => {
  return (
    <div className="row text-center" style={{ paddingTop: "20px" }}>
      <h2 className="headingH2" style={{color:'#000'}}>
        <span className="clr-blue">Our</span> Story
      </h2>
      <div className="storyPara">
        <p>
          Rawmart is a specialist in the domain of SME consulting and
          counselling with the aim to prepare manufacturers & construction
          companies for PSU requirements impeccably.
        </p>
        <p>
          {" "}
          We work with 42 out of a total of 348 PSU companies in India. From
          Indian Railways, BEL and BHEL to HPCL, HAL, NTPC and more, our list of
          companies is growing consistently.
        </p>
        <p>
          {" "}
          We have been a strategic partner of various MNCs for supporting them
          with our services.{" "}
        </p>
        <p>
          Therefore, we have not only endeavored to maintain maximum
          tech-backing in operations but have also fortified our expert analysis
          with Artificial Intelligence, we firmly believe that technology drives
          the future capabilities of companies.
        </p>
        <p>
          {" "}
          With us, our team is our greatest asset. Our professionals are
          trained, certified and qualified to understand our customer’s product,
          machinery and plant capabilities along with their current level of
          expertise and experience, to get the best possible lead for them.
        </p>
        <p>
          {" "}
          Our in-depth industry experience and know-how, allows us to offer
          profound and insightful knowledge about eligibility and approval
          compliance to our clients along with complete training with respect to
        </p>

        <ul className="list">
          <li>Preparing essential documents </li>
          <li>Participating in e-tendering</li>
          <li>Assisting in winning orders amidst aggressive competition</li>
        </ul>
        <p>
          With comprehensive online and offline information our aim is to
          facilitate smoothness, swiftness and faultlessness while working with
          any government entity. We always endeavor to encourage as many
          manufacturers, traders and enterprises as we can to enhance their
          accessibility to government projects, and assure maximum ease in
          business development as well as order conversions.{" "}
        </p>
        <p className="tagline">
          "Tender Management and Consulting Together We Envision, Inspire and
          Transform Your Business"
        </p>
        <p>
          The struggles of otherwise promising SMEs while navigating through
          innumerable industry related challenges these days, are real. Our
          competence to power sustainable growth and our skills to leverage
          industry expertise and contextual knowledge to make the journey
          smoother for these entities, has backed the foundation of Raw Mart.{" "}
        </p>
        <p> Some of the core issues that SMEs face these days include: </p>
        <ul className="list">
          <li>Lack of understanding of the right opportunities</li>
          <li>Uncertainty about present and future market requirements</li>
          <li> Misaligned vision and strategy Poor strategy</li>
          <li>Poor strategy execution and value realization</li>
        </ul>
        <p>
          execution and value realization Navigating successfully around these
          impediments translates to gaining greater insight and clarity on
          existing issues and opportunities. Understanding the complexity of
          these challenges, our team always works as an extension of client
          companies to scale their organization, improve productivity, gain
          competitive advantage and much more.
        </p>
      </div>
    </div>
  );
};

export const Management = () => {
  return (
    <div className="row text-center" style={{ paddingTop: "20px" }}>
      <h2 className="headingH2" style={{ color: "#000", marginBottom:'5px' }}>
        <span className="clr-blue">Tender Management</span> and Consulting
      </h2>
      <p className="tagline" style={{marginBottom: '10px'}}>
        "Together We Envision, Inspire and Transform Your Business"
      </p>
      <div className="storyPara">
        <p>
          The struggles of otherwise promising SMEs while navigating through
          innumerable industry related challenges these days, are real. Our
          competence to power sustainable growth and our skills to leverage
          industry expertise and contextual knowledge to make the journey
          smoother for these entities, has backed the foundation of Raw Mart.{" "}
        </p>
        <p> Some of the core issues that SMEs face these days include: </p>
        <ul className="list">
          <li>Lack of understanding of the right opportunities</li>
          <li>Uncertainty about present and future market requirements</li>
          <li> Misaligned vision and strategy Poor strategy</li>
          <li>Poor strategy execution and value realization</li>
        </ul>
        <p>
          execution and value realization Navigating successfully around these
          impediments translates to gaining greater insight and clarity on
          existing issues and opportunities. Understanding the complexity of
          these challenges, our team always works as an extension of client
          companies to scale their organization, improve productivity, gain
          competitive advantage and much more.
        </p>
      </div>
    </div>
  );
}

export const Approch = () => {
  return (
    <div className="row text-center" style={{ paddingTop: "20px" }}>
      <h2 className="headingH2" style={{ color: "#000" }}>
        <span className="clr-blue">Our</span> Approch
      </h2>
      <div className="storyPara">
        <p>
          In today’s competitive and volatile economy, participating in
          Government tenders can’t be a secondary option, mainly because
          businesses can’t afford to neglect and lose the enormous public
          procurement market, which is completely recession free.{" "}
        </p>
        <p>
          Our professional help and advice significantly increase chances of
          client companies to win tenders and bids. It does not matter if the
          concerned client organization is completely new to tendering and
          requires lots of hands-on support, or a seasoned bidder that needs
          advice on gaining a few more points to improve their results. Our
          tender consultants will make sure every client receives a highly
          attentive, personalized service to assist with tendering success.
        </p>
        <p>
          We always work in the favor of our clients to minimize costs and
          increase their profit margins significantly.
        </p>
        <h3 className="experienceh3">Our experience with</h3>
        <ul className="experienceList">
          <li>
            <img src="assets/images/consultingCompany/logo3.png" alt="logo" />
            <p>Manufacturers</p>
          </li>
          <li>
            <img src="assets/images/consultingCompany/logo4.png" alt="logo" />
            <p>Trader</p>
          </li>
          <li>
            <img src="assets/images/consultingCompany/logo2.png" alt="logo" />
            <p>Stockist</p>
          </li>
          <li>
            <img src="assets/images/consultingCompany/logo1.png" alt="logo" />
            <p>Contractor</p>
          </li>
        </ul>
        {/* slider of logo */}
        <p>
          We work very closely with the vendors to understand their product
          expertise, as we have a lot of exposure with the PSU sector and deep
          understanding of the requirements of the manufacturing units.{" "}
        </p>
        <p>
          We communicate to our customers the opportunities available with the
          PSU according to their current infrastructure so that they can start
          with a minimum investment.
        </p>
        <p>
          We also contribute in new investment planning for the manufacturing
          units which makes them eligible for proprietary products to get more
          business and appreciable margins.
        </p>
        <p>
          At the moment, we are working with more than 42 PSU and government
          manufacturing units to develop vendors with them and give them
          exposure to business opportunities available with Indian Public Sector
          Units. We have our expertise in tender bidding and representing
          customers profile in such a way that it can be fitted suitability as
          per the Minimum requirements to win an order in a tender floated.
        </p>
        <p>
          Rawmart is also the local representative of client vendors. We provide
          complete licensing services required by our partner vendors at the
          premises of the manufacturing unit itself. We support the latter in
          logistics, documentation and interaction with the government
          department as well as meeting bill and following up the release of
          payments.
        </p>
      </div>
    </div>
  );
};

export const GovOrg = () => {
  let data = [
    {
      heading: "Hindustan Aeronautics Limited",
      imgSrc: "hal",
      number: 16,
    },
    {
      heading: "Bharat Dynamics Limited",
      imgSrc: "bdl",
      number: 2,
    },
    {
      heading: "Ordnance Factory Board",
      imgSrc: "iof",
      number: 26,
    },
    {
      heading: "ISRO",
      imgSrc: "isro",
      number: 9,
    },
    {
      heading: "Indian Railways",
      imgSrc: "railway",
      number: 48,
    },
    {
      heading: "Bharat Electronic Ltd - BEL",
      imgSrc: "bel",
      number: 9,
    },
    {
      heading: "Indian Oil Corporation",
      imgSrc: "indianoil",
      number: 8,
    },
    {
      heading: "BHEL",
      imgSrc: "bhel",
      number: 19,
    },
    {
      heading: "BEML Bangalore",
      imgSrc: "beml",
      number: 5,
    },
    {
      heading: "NHPC LIMITED",
      imgSrc: "nhpc",
      number: 20,
    },
    {
      heading: "Mazgaon Dock",
      imgSrc: "md",
      number: "1",
    },
    {
      heading: "Goa Shipyard Ltd",
      imgSrc: "goa",
      number: 2,
    },
    {
      heading: "RCI Hyderabad	",
      imgSrc: "drdo",
      number: 1,
    },
    {
      heading: "NPCIL",
      imgSrc: "npcil",
      number: 7,
    },
    {
      heading: "ONGC",
      imgSrc: "ongc",
      number: 8,
    },
    {
      heading: "SAIL STEEL PLANT",
      imgSrc: "sail",
      number: 2,
    },
    {
      heading: "ECIL",
      imgSrc: "ecil",
      number: 2,
    },
  ];

  let miningCompanies = [
    {
      heading: "MINISTRY OF COAL, DHANBAD JHARKHAND",
      imgSrc: "icm",
    },

    {
      heading: "HINDUSTAN COPPER LIMITED",
      imgSrc: "hcl",
    },
    {
      heading: "NATIONAL ALUMINIUM COMPANY",
      imgSrc: "nalco",
    },
    {
      heading: "URANIUM CORPORATION OF INDIA LIMITED",
      imgSrc: "ucil",
    },
    {
      heading: "MAHANADI COALFIELDS LIMITED",
      imgSrc: "mcl",
    },
    {
      heading: "SOUTH EASTERN COALFIELDS LIMITED",
      imgSrc: "secf",
    },
    {
      heading: "EASTERN COALFIELDS LIMITED",
      imgSrc: "ec",
    },
  ];

  let energySector = [
    {
      heading: "Electricity Department",
      imgSrc: "ed",
    },
    {
      heading: "HARYANA BOARD CORPORATION",
      imgSrc: "hbc",
    },
    {
      heading: "NTPC",
      imgSrc: "ntpc",
    },
    {
      heading: "GUJARAT ENERGY TRANSMISION AND CORPORATION LTD",
      imgSrc: "getco",
    },
    {
      heading: "WEST BENGAL STATE ELECTRICITY DISTRIBUTION COMPANY LIMITED",
      imgSrc: "wbedcl",
    },
    {
      heading: "MADHYA PRADESH POWER GENERATION COMPANY LIMITED",
      imgSrc: "mpdcl",
    },
    // {
    //   heading: "RAJASTHAN RAJYA VIDYUT UTPADAN NIGAM LIMITED",
    //   imgSrc: "rvpn",
    // },
    {
      heading: "POWER DEVELOPMENT DEPARTMENT JAMMU AND KASHMIR",
      imgSrc: "jkpd",
    },
    {
      heading: "RAJASTHAN RAJYA VIDYUT NIGAM LIMITED",
      imgSrc: "rvpn",
    },
    // {
    //   heading: "POWER DEVELOPMENT DEPARTMENT",
    //   imgSrc: "jkpd",
    // },
    {
      heading: "WEST BENGAL POWER DEVELOPMENT CORPORATION LIMITED",
      imgSrc: "wbpdcl",
    },
    {
      heading: "BHEL",
      imgSrc: "bhel",
    },
    {
      heading: "MADHYANCHAL VIDYUT VITRAN NIGAM LIMITED",
      imgSrc: "mvval",
    },
    {
      heading: "DAKSHINANACHAL VIDYUT VITRAN NIGAM LIMITED",
      imgSrc: "dvvnl",
    },
    // {
    //   heading: "MADHYA PRADESH POWER GENERATING COMPANY LIMITED",
    //   imgSrc: "mpdcl",
    // },
  ];
  let defence = [
    {
      imgSrc: "mes",
      heading: "MES",
    },
    {
      heading: "OFBs Ordnance Factory BOARD",
      imgSrc: "iof",
    },
    {
      heading: "MINISTRY OF DEFENSE",
      imgSrc: "mod",
    },
  ];
  return (
    <div className="">
      <div className="row text-center" style={{ paddingTop: "20px" }}>
        <h2 className="headingH2" style={{ color: "#000" }}>
          <span className="clr-blue">Government</span> Organisations
        </h2>
        <div className="">
          <div className="flx-wrap">
            {data.map((i, j) => {
              return (
                <div className="col-md-3 centerData">
                  <div className="">
                    <div className="gal-item">
                      <ImageSetion key={j} data={i} />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="row text-center" style={{ paddingTop: "70px" }}>
        <h2 className="headingH2" style={{color:'#000'}}>
          <span className="clr-blue">Consultation </span> Companies
        </h2>
        <p className="subHeading">Mining Companies In India</p>
        <div className="">
          <div className="flx-wrap">
            {miningCompanies.map((i, j) => {
              return (
                <div className="col-md-3 centerData">
                  <div className="">
                    <div className="gal-item">
                      <ImageSetion key={j} data={i} />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <p className="subHeading">Defense</p>
        <div className="">
          <div className="flx-wrap">
            {defence.map((i, j) => {
              return (
                <div className="col-md-3 centerData">
                  <div className="">
                    <div className="gal-item">
                      <ImageSetion key={j} data={i} />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <p className="subHeading">Energy Sector</p>
        <div className="">
          <div className="flx-wrap">
            {energySector.map((i, j) => {
              return (
                <div className="col-md-3 centerData">
                  <div className="">
                    <div className="gal-item">
                      <ImageSetion key={j} data={i} />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export const ImageSetion = ({ data }) => {
  let { imgSrc, number, heading } = data;
  return (
    <div className="black-hover">
      <img
        className="img-full"
        src={`assets/images/govorg/${imgSrc}.png`}
        alt="Project1"
      />
      <div className="tour-layer delay-1"></div>
      <div className="vertical-align flx-clm">
        {/* <div className="border"> */}
        <h5>{heading}</h5>
        <p>{number} Factories</p>
        {/* </div> */}
      </div>
    </div>
  );
};
export const Railway = () => {
  return (
    <div className="row text-center" style={{ paddingTop: "20px" }}>
      <h2 className="headingH2" style={{ color: "#000" }}>
        <span className="clr-blue">Indian</span> Railway
      </h2>
      <div className="storyPara">
        <p>
          Indian Railways (IR) is a statutory body under the jurisdiction of
          Ministry of Railways, Government of India, that operates India's
          national railway system. It manages the fourth-largest railway
          network in the world by size, with a route length of 67,956km.
        </p>
        <p>
          IR is divided into 18 zones, headed by general managers who report to
          the Railway Board. The zones are further subdivided into 71
          operating divisions, Indian Railways is a vertically-integrated
          organization that produces majority of its locomotives & rolling stock
          at in-house production units, with a few recent exceptions.
        </p>
        <p className="boldText">Locomotives:</p>
        <ul>
          <li>
            Chittaranjan Locomotive Works in Chittaranjan, West Bengal
            manufactures electric locomotives.
          </li>
          <li>
            Banaras Locomotive Works in Varanasi, Uttar Pradesh manufactures
            electric locomotives.
          </li>
          <li>
            Diesel Locomotive Factory, Marhowrah, Bihar, a Joint Venture of
            Indian Railways & General Electric manufactures high-capacity diesel
            locomotives, used especially for freight transportation.
          </li>
          <li>
            Electric Locomotive Factory in Madhepura, Bihar, a Joint Venture of
            Indian Railways and Alstom SA manufactures electric locomotives.
          </li>
          <li>
            Diesel-Loco Modernization Works in Patiala, Punjab upgrades and
            overhauls locomotives. They also manufacture electric locomotives.
          </li>
        </ul>

        {/* slider of logo */}
        <p className="boldText">Rolling Stock:</p>
        <ul>
          <li>Integral Coach Factory in Perambur, Tamil Nadu</li>
          <li>Rail Coach Factory in Kapurthala, Punjab</li>
          <li>Modern Coach Factory in Raebareli, Uttar Pradesh</li>
          <li>Marathwada Rail Coach Factory in Latur, Maharashtra</li>
        </ul>
        <p className="boldText">Wheel & Axle:</p>
        <ul>
          <li>Rail Wheel Factory in Bangalore, Karnataka</li>
          <li>Rail Wheel Plant in Chhapra, Bihar</li>
        </ul>
        <p>
          Repair and maintenance of this vast fleet of rolling stock is carried
          out at 44 loco sheds, 212 carriage & wagon repair units and 45
          periodic overhaul workshops across various zones of IR.
        </p>
        <p>
          Procurement Value: To manage the supply chain of the goods for such
          production units, Ministry of Railways buys goods and services worth
          70000 Cr per annum. Which have grown 30 percent.
        </p>
        <p>
          IR is also a major shareholder in following public sector undertakings
          (PSU) and other organizations that are related to rail transport in
          India. Notable among the list are:
        </p>
        <p className="boldText">
          Financing, construction and project implementation
        </p>
        <ul>
          <li>Indian Railway Finance Corporation (IRFC)</li>
          <li>RITES Ltd</li>
          <li>Indian Railway Construction Corporation (IRCON)</li>
          <li>Mumbai Rail Vikas Corporation (MRVC) (51%)</li>
          <li>Rail Vikas Nigam Limited (RVNL)</li>
        </ul>
        <p className="boldText">Land and station development</p>
        <ul>
          <li>Rail Land Development Authority</li>
          <li>Indian Railway Stations Development Corporation</li>
        </ul>
        <p className="boldText">Rail infrastructure</p>
        <ul>
          <li>
            Dedicated Freight Corridor Corporation of India Limited (DFCCIL)
          </li>
          <li>Pipavav Railway Corporation Ltd PRCL (50%)</li>
        </ul>
        <p className="boldText">Passenger and freight train operations</p>
        <ul>
          <li>Konkan Railway Corporation Limited (KRCL)</li>
          <li>Container Corporation of India (CONCOR)</li>
        </ul>
        <p className="boldText">Our Services in a Nutshell: </p>
        <ul>
          <li>Identification of Opportunities Product Selection </li>
          <li>Gathering Market Intelligence</li>
          <li>New Product Development, technical consulting</li>
          <li>Latest Purchase rates analysis</li>
          <li>Selection of Most Suitable Tenders</li>
          <li>Tender notifications</li>
          <li>Tender Submission Training</li>
          <li>Getting Approval and Certifications</li>
          <li>Tender Preparation</li>
          <li>Vendor Registration with units</li>
          <li>Product registration, approval</li>
          <li>E-tendering support</li>
          <li>Gem profile setup</li>
          <li>On site liasioning, commissioning</li>
          <li>Cost cutting, raw material procurement assistance</li>
          <li>Third party product inspection</li>
          <li>Creating product testing inspection plans for your vendors</li>
        </ul>
      </div>
    </div>
  );
};
export const PsuCompany = () => {
  return (
    <div className="row text-center" style={{ paddingTop: "20px" }}>
      <section className="">
        <div className="container">
          <h2 className="headingH2" style={{ color: "#000" }}>
            Our <span className="clr-blue">Process</span>
          </h2>
        </div>
        <div className="container">
          <div className="storyPara">
            <p>
              PSU companies are wholly or partly owned by the Government of
              India or state governments, which comprises a total procurement of
              more than ₹10 lakh crore annually. This makes PSU a limitless
              marketplace for SMEs. Moreover, with their consistently increasing
              requirements, we think no industry should miss the opportunity to
              grow in this segment.
            </p>
            <p>
              Along with this, we also intend to provide procurement leads of
              the Private Sector from our existing network of more than 200+
              Industries from different regions of India, that have huge
              procurement on a regular basis.
            </p>
          </div>
          <div className="row text-center">
            <img className="" src="assets/images/ourprocess.svg" alt="svg" />
          </div>
        </div>
      </section>
    </div>
  );
};

export const OurServices = () => {
  const data = [
    {
      imgsrc: "1",
      title: "Gathering Market Intelligence",
    },

    {
      imgsrc: "2",
      title: "New Product Development",
    },
    {
      imgsrc: "3",
      title: "Latest Purchase rates analysis",
    },
    {
      imgsrc: "4",
      title: "Selection of Most Suitable Tenders",
    },
    {
      imgsrc: "5",
      title: "Tender notifications",
    },
    {
      imgsrc: "6",
      title: "Tender Submission Training",
    },
    {
      imgsrc: "7",
      title: "Getting Approval and Certifications",
    },
    {
      imgsrc: "8",
      title: "Pre-Bid Questionnaire Writing Services",
    },
    {
      imgsrc: "9",
      title: "Tender Preparation",
    },
    {
      imgsrc: "10",
      title: "Pre-Bid Questionnaire Response Review and Audit",
    },
    {
      imgsrc: "11",
      title: "Vendor Document Submission & Registration",
    },
    {
      imgsrc: "12",
      title: "Raw material procurement Support",
    },
  ];

  return (
    <div className="container" style={{ marginTop: "20px" }}>
      <div className="row ">
        <div className="head-section wdt-100 center-data">
          <div className="col-lg-5 col-md-6 col-sm-4 col-xs-12">
            <h3 className="center-text" style={{ color: "#000", fontSize:'36px' }}>
              Our <span className="clr-blue">Services</span>
            </h3>
          </div>
          <div className="col-lg-7 col-md-6 col-sm-8 col-xs-12 noborder">
            <p className="font-18">
              As global competition intensifies, keeping up with market changes
              has become critical. At Rawmart, our expertise comes from being
              deeply embedded in the commodity markets we serve, with reporters
              in multiple global locations, that allow you to keep track of both
              global and regional businesses as well as market movements.
            </p>
          </div>
        </div>
        <div className="col-md-12 row flexWrap">
          {data.map((i, j) => {
            return (
              <ServicesBox data={i} key={j} first={j === 0 ? true : false} />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export const ServicesBox = ({ data }) => {
  let { title, imgsrc } = data;
  return (
    <div className="col-md-6 col-sm-6 col-xs-12 service-column service4-column featureBox ">
      <span className="service4-icons icons service-manufactureicon imageBoxFeatureBox">
        <img src={`/assets/images/services/img${imgsrc}.png`} alt="icons" />
      </span>
      <div className="service4-desc pd-t-70">
        <h5 className="mt-50 center-text">{title}</h5>
      </div>
    </div>
  );
};
export default SubSections;
