export default function ProductBox(data){

  return (
    <div className="col-lg-4 col-md-4 col-sm-8 col-xs-12 img pad_zero cloneditem-1" style={{padding:'15px'}}>
                <img
                  className="img-full  customImage"
                  src={`assets/images/products/${data.data}.jpg`}
                  alt="Project1"
                />
      {/* <div className="grid">
        
          <div className="col-md-4">
            <div className="gal-item">
              <a className="black-hover" href="agriculture.html">
                <img
                  className="img-full  customImage"
                  src={`assets/images/products/${data.data}.jpg`}
                  alt="Project1"
                />
                {/* <div className="tour-layer delay-1"></div>
              <div className="vertical-align">
                <div className="border">
                  <h5>{data.name}</h5>
                </div>
                <div className="view-all hvr-bounce-to-right slide_learn_btn view_project_btn">
                  <span>View Project</span>
                </div>
              </div> */}
              {/* </a>
            </div>
          </div> */}
      {/* </div>  */}
    </div>
  );
}