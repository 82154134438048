import './style.css';
import React, { useEffect } from "react";
// import ServicesBox from "./servicesBox";
export default function Ourservices({setSubFooter}){
  useEffect(() => {
    window.scrollTo(0, 0);
    setSubFooter(false)
  }, []);
  const data = [
    {
      imgsrc: "1",
      title: "Gathering Market Intelligence",
    },

    {
      imgsrc: "2",
      title: "New Product Development",
    },
    {
      imgsrc: "3",
      title: "Latest Purchase rates analysis",
    },
    {
      imgsrc: "4",
      title: "Selection of Most Suitable Tenders",
    },
    {
      imgsrc: "5",
      title: "Tender notifications",
    },
    {
      imgsrc: "6",
      title: "Tender Submission Training",
    },
    {
      imgsrc: "7",
      title: "Getting Approval and Certifications",
    },
    {
      imgsrc: "8",
      title: "Pre-Bid Questionnaire Writing Services",
    },
    {
      imgsrc: "9",
      title: "Tender Preparation",
    },
    {
      imgsrc: "10",
      title: "Pre-Bid Questionnaire Response Review and Audit",
    },
    {
      imgsrc: "11",
      title: "Vendor Document Submission & Registration",
    },
    {
      imgsrc: "12",
      title: "Raw material procurement Support",
    },
  ];
  return (
    <div className="">
      <div className="bg-img-services">
        <h2 className="headingH2">Our Services</h2>
      </div>
      <section className="home4-service-section">
        <div className="container">
          <div className="row ">
            <div className="head-section wdt-100 center-data">
              <div className="col-lg-5 col-md-6 col-sm-4 col-xs-12">
                <h3 className="center-text" style={{ color: "#000", fontSize:'36px' }}>
                  Our <span className="clr-blue">Services</span>
                </h3>
              </div>
              <div className="col-lg-7 col-md-6 col-sm-8 col-xs-12">
                <p className="font-18">
                  As global competition intensifies, keeping up with market
                  changes has become critical. At Rawmart, our expertise comes
                  from being deeply embedded in the commodity markets we serve,
                  with reporters in multiple global locations, that allow you to
                  keep track of both global and regional businesses as well as
                  market movements.
                </p>
              </div>
            </div>
            {/* <div className="col-md-12 row flexWrap">
              {data.map((i, j) => {
                return (
                  <ServicesBox data={i} key={j} first={j === 0 ? true : false} />
                );
              })}
            </div> */}
            <div className="row text-center" style={{ paddingTop: "70px" }}>
              <h2 className="headingH2" style={{ color: "#000" }}>
                <span className="clr-blue">Procurement </span> Services
              </h2>
              <div className="storyPara">
                <p>
                  Procurement Services at Rawmart include expert procurement
                  consulting, intuitive strategic sourcing and offering
                  insightful data-backed market intelligence.{" "}
                </p>
                <p>
                  We have pledged to continually improve our services embracing
                  newer and more refined policies to make raw material
                  procurement relaxed, agreeable and profitable for SMEs.
                </p>
                <p>
                  We further analyse the best market persona for you in the most
                  affordable way. We calculate the best rates for you by
                  analysing costs right from ‘procurement to delivery of
                  material’. With us, you can benefit from comprehensive
                  rate-comparison between different raw material sources that
                  can help you decide upon the ideal deal.
                </p>

                <h2 className="headingH2" style={{ marginTop: "20px", color: "#000"  }}>
                  <span className="clr-blue">Consultancy </span> Services
                </h2>
                <p className="tagline">
                  "Tender Management and Consulting Together We Envision,
                  Inspire and Transform Your Business"
                </p>
                <p>
                  The struggles of otherwise promising SMEs while navigating
                  through innumerable industry-related challenges these days are
                  real. Our competence to power sustainable growth and our
                  skills to leverage industry expertise and contextual knowledge
                  to make the journey smoother for these entities have backed
                  the foundation of Raw Mart.
                </p>
                <p>
                  Some of the core issues that SMEs face these days include:
                </p>
                <ul>
                  <li>Lack of understanding of the right opportunities</li>
                  <li>
                    Uncertainty about the present and future market requirements
                  </li>
                  <li>Misaligned vision and strategy</li>
                  <li> Poor strategy execution and value realization</li>
                </ul>
                <p>
                  Navigating successfully around these impediments translates to
                  gaining greater insight and clarity on existing issues and
                  opportunities. Understanding the complexity of these
                  challenges, our team always works as an extension of client
                  companies to scale their organization, improve productivity,
                  gain a competitive advantage, and much more.
                </p>
                {/* slider of logo */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}