/* eslint-disable jsx-a11y/role-supports-aria-props */
import React, { useEffect, useRef, useState } from "react";
import { Link,NavLink  } from "react-router-dom";
import "./style.css";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";
function NavigationBar(props) {
	const { innerWidth } = window;
	const navbar = useRef(null);
	let [fixed, setFixed] = useState(innerWidth > 991 ? false : true);
	const [open, setOpen] = useState(false);
	useEffect(() => {
		window.addEventListener("scroll", (e) => {
			if (window.scrollY > navbar.current.offsetTop) {
				setFixed(true);
			} else {
				setFixed(false);
			}
		});
	}, []);
	const navigateToAboutUS = () => {
		setTimeout(() => {
			window.scrollTo(0, 600);
			setOpen(!open);
		}, 300);
	};
	return (
		<header className="header2">
			<nav
				id="main-navigation-wrapper"
				ref={navbar}
				style={{
					// background: "var(--blue)",
					display: "flex",
					alignItems: "center",
					flexDirection: innerWidth <= 991 ? "column" : "row",
					background: "#fff",
					borderBottom: "2px solid #233064",
				}}
				className={`navbar navbar-default navbar2-wrap ${
					fixed ? "sticky_header slideInDown animated" : "slideIn animated "
				}`}
			>
				{innerWidth <= 991 ? null : (
					<Link to="/">
						<img
							src="assets/images/img/logo.png"
							alt=""
							style={{
								width: "208px",
								height: "92px",
								marginLeft: "35px",
								cursor: "pointer",
							}}
						/>
					</Link>
				)}
				<div
					className="container"
					style={{
						display: "flex",
						alignItems: "center",
						flexDirection: innerWidth <= 991 ? "column" : "row",
						justifyContent: "center",
					}}
				>
					<div
						className="navbar-header"
						style={{
							background: "#fff",
							alignItems: "center",
							display: "flex",
							justifyContent: "flex-start",
						}}
					>
						{innerWidth <= 991 ? (
							<Link to="/">
								<img
									src="assets/images/img/logo.png"
									alt=""
									style={{
										width: "208px",
										height: "92px",
										cursor: "pointer",
									}}
								/>
							</Link>
						) : null}
						<Button
							onClick={() => setOpen(!open)}
							aria-controls="example-fade-text"
							aria-expanded={!open}
							type="button"
							style={{ marginLeft: "auto" }}
							data-toggle="collapse"
							data-target="#main-navigation"
							className="navbar-toggle collapsed"
						>
							<span className="sr-only">Toggle navigation</span>
							<span className="icon-bar" style={{ background: "#000" }}></span>
							<span className="icon-bar" style={{ background: "#000" }}></span>
							<span className="icon-bar" style={{ background: "#000" }}></span>
						</Button>
					</div>
					<div
						className="var2-nav var3-nav"
						style={{
							background: "#fff",
							width: innerWidth <= 991 ? "100%" : null,
						}}
					>
						{innerWidth <= 991 ? (
							<Collapse in={innerWidth <= 991 ? open : !open}>
								<div id="">
									<div id="" className="centerDatanav">
										<ul className="nav navbar-nav">
											<li className="dropdown"></li>
											<li className="dropdown ">
												<NavLink  to="/" onClick={() => setOpen(!open)}>
												SME Ecosystem
												</NavLink >
											</li>
											<li className="dropdown ">
												<NavLink  to="/Invovle" onClick={navigateToAboutUS}>
												Involvement
												</NavLink >
											</li>
											<li className="dropdown ">
												<NavLink  to="/Solve" onClick={navigateToAboutUS}>
												Solutions
												</NavLink >
											</li>
											<li className="dropdown">
												<NavLink  to="/Evolve" onClick={() => setOpen(!open)}>
												Evolvement
												</NavLink >
											</li>
										</ul>
										<NavLink 
											className="header-requestbtn header2-requestbtn header3-requestbtn hvr-bounce-to-right"
											to="/contactus"
											onClick={() => setOpen(!open)}
										>
											Contact Us
										</NavLink >
									</div>
								</div>
							</Collapse>
						) : (
							<div id="">
								<div id="" className={innerWidth <= 991 ? "centerDatanav" : ""}>
									<ul className="nav navbar-nav">
										<li className="dropdown"></li>
										<li className="dropdown ">
											<NavLink  to="/" activeclassname="active">SME Ecosystem</NavLink >
										</li>
										<li className="dropdown">
											<NavLink  to="/Invovle" activeclassname="active">Involvement</NavLink >
										</li>
										<li className="dropdown">
											<NavLink  to="/Solve" activeclassname="active">Solutions</NavLink >
										</li>
										<li className="dropdown">
											<NavLink  to="/Evolve" activeclassname="active">Evolvement</NavLink >
										</li>
										<li className="dropdown">
											<NavLink  to="/contactus" activeclassname="active">Contact Us</NavLink >
										</li>
									</ul>
								</div>
							</div>
						)}
					</div>
				</div>
			</nav>
		</header>
	);
}
export default NavigationBar;
