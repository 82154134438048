/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";
import "./style.css";
import "swiper/css";
import { Link } from "react-router-dom";
function Slider({
	overlay,
	images,
	slidesPerView,
	height,
	imgWidth,
	heading,
	subheading,
}) {
	let hght = height ? height : null;
	let wdth = imgWidth ? imgWidth : "";
	let maxWdth = imgWidth ? "400px" : "auto";
	const { innerWidth } = window;
	SwiperCore.use([Autoplay]);
	return (
		<div className="sliderSection" style={{ height: hght }}>
			<div
				className=""
				style={{ marginTop: innerWidth <= 991 ? "90px" : null }}
			>
				<Swiper
					spaceBetween={0}
					slidesPerView={slidesPerView ? slidesPerView : 1}
					freeMode={true}
					loop={true}
					autoplay={{ delay: 2000 }}
					speed={2000}
					breakpoints={{
						200: {
							slidesPerView: 1,
						},
						400: {
							slidesPerView: slidesPerView ? slidesPerView / 2 : 1,
						},
						700: {
							slidesPerView: slidesPerView ? slidesPerView : 1,
						},
						1200: {
							slidesPerView: slidesPerView ? slidesPerView : 1,
						},
					}}
				>
					{images.map((i, j) => {
						let path = i.path;
						return (
							<SwiperSlide key={j}>
								<div className="wrapper-img">
									{overlay ? (
										<div
											className="overlay"
											style={innerWidth <= 991 ? { height: "340px" } : {}}
										>
											<div
												className="content"
												style={{
													display: "flex",
													alignItem: "center",
													flexDirect: "column",
												}}
											>
												{heading[j] ? <h2>{heading[j]}</h2> : null}
												{subheading ? <p>{subheading}</p> : null}
												<Link
													data-animation="animated fadeInUp"
													className="header-requestbtn more-infobtn hvr-bounce-to-right responsiv-btn"
													to={path}
												>
													Know more
												</Link>
											</div>
										</div>
									) : null}
									<img
										src={i.img}
										alt="Image"
										style={{ width: wdth, maxWidth: maxWdth }}
									/>
								</div>
							</SwiperSlide>
						);
					})}
				</Swiper>
			</div>
		</div>
	);
}

export default Slider;
