import { useEffect } from "react";
export default function ProfilingAndBusinessStrategy() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<section className="home4 recent-project-section pad100-top projectsec1">
			<div className="container" style={{marginTop:"30px"}}>
				<h3
					className="white-color h3Heading heading-h3"
					style={{ marginBottom: "0px" }}
				>
					Profiling And
					<span className="clr-blue"> Business Strategy</span>
				</h3>
				<p
					style={{
						marginBottom: "20px",
						fontSize: "22px",
						marginTop: "10px",
						textAlign: "center",
					}}
				>
					Defining path to stay one ahead from your competitors
				</p>

				<div className="profilingAndBusiness__img" style={tenderManagement}>
					<img
						src="\assets\images\banners\consulting pic 3.png"
						alt="profilingAndBusiness__img__img"
						// style={{ tenderManagementImg }}
						style={{ objectFit: "contain", height: "100%", width: "100%" }}
					/>
				</div>
				<p
					style={{
						fontSize: "16px",
						marginBottom: "20px",
					}}
				>
					A business only succeeds if it can fulfil the changing needs of its
					clients both more completely and more profitably than its competitors.
					Nowadays, with digital disruption obscuring industry boundaries and
					geopolitics challenging long-held assumptions, those needs are
					changing faster than ever.
				</p>

				<p
					style={{
						fontSize: "16px",
						marginBottom: "20px",
						textAlign: "justify",
					}}
				>
					In this ever-changing world, companies have to think fast and stay
					agile. Which requires strategies that work within the real world. With
					experience over the esteem chain, end-to-end, only Rawmart makes a
					difference for the clients by making strategies that come not just
					from knowing, but from the know-how of doing.
				</p>

				<p
					style={{
						fontSize: "16px",
						marginBottom: "20px",
						textAlign: "justify",
					}}
				>
					We at Rawmart advise organisations on high-level choices in an
					impartial design, using profound industry information to deliver the
					finest results. Our service generally implies advising at the
					highest-level of a company.
				</p>
				<p
					style={{
						fontSize: "16px",
						marginBottom: "20px",
						textAlign: "justify",
					}}
				>
					Rawmart works across every industry, with private and public sector
					bodies on a wide range of issues.
				</p>

				<p
					style={{
						fontSize: "16px",
						marginBottom: "20px",
						textAlign: "justify",
					}}
				>
					Rawmart Procedure leverages this advantage in our work with boards,
					CEOs, and executives to create benefits for shareholders and
					stakeholders by characterising and answering their most key business
					questions on subjects like growth, profitability, technology-driven
					transformation, M&A, working models, and sustainability.
				</p>

				<p
					style={{
						fontSize: "22px",
						marginBottom: "20px",
						textAlign: "center",
					}}
				>
					Export Consulting
				</p>
				<p style={{ fontSize: "16px", marginBottom: "20px" }}>
					The importing and exporting process can be difficult to those who are
					new with the documentation, shipping procedures and different parts
					included within the development of goods from one nation to another.
					Indeed for the prepared importer/exporter, doing it all alone can be a
					hassle.
				</p>
				<p style={{ fontSize: "16px", marginBottom: "20px" }}>
					Our import/export specialists have worked in each aspect of the trade,
					and not as it were can we offer assistance to advise importers and
					exporters, but we moreover give customs brokerage and documentation
					services. A small piece of advice can go a long way to help you get
					your international business up and running with less bumps in the
					street.
				</p>
				<p style={{ fontSize: "16px", marginBottom: "20px" }}>
					Rawmart could be a multi-disciplinary consultancy association locked
					in in different consultancy lines particularly the field of export
					Incentives.Our involvement within the pertinent businesses, gives us a
					business-oriented approach. Coupled with our expertise on the matter
					of Foreign Trade Policy, and custom Strategies & related issues, over
					diverse segments of businesses, it makes a difference as we best
					optimise the benefits for our clients and discover innovative
					solutions.
				</p>
				<p style={{ fontSize: "16px", marginBottom: "20px" }}>
					Rawmart Specialises in making a difference in businesses growth
					similar to yours in profitably growing through worldwide markets. We
					offer expert support and guidance within the areas you need to make
					sure you're completely prepared and have a winning technique in place
					for an effective export journey.
				</p>
				<p style={{ fontSize: "16px", marginBottom: "20px" }}>
					Using our tried and tested techniques, we work with you to evaluate
					your level of export status Furthermore, we plan and implement a trade
					strategy that actually works and delivers results. We help you to
					maintain a strategic distance from the expensive mistakes, stop you
					going down the blind alleys or taking the wrong turns.
				</p>
			</div>
		</section>
	);
}

const tenderManagement = {
	height: "400px",
	widht: "400px",
	marginBottom: "10px",
};

const tenderManagementImg = {
	height: "100%",
	widht: "100%",
	objectContent: "cover",
};
