export function FeatureBox(data) {
  let { title, des, des2, list, img, first } = data.data;
  let paddingValue = data.first ? "0px!important" : "60px"
  return (
    <div className="col-md-6 col-sm-6 col-xs-12 service-column service4-column featureBox">
      <span className="service4-icons icons service-manufactureicon imageBoxFeatureBox">
        <img src={img} alt="icons" />
      </span>
      <div className="service4-desc pdt-30" style={{ paddingTop: '60px' }}>
        <h5>{title}</h5>
        <p className="paraSection marbtm20">{des}</p>
        {list ? (
          <ul className="listingFeature">
            {list.map((i, j) => {
              return <li key={j}>{i}</li>;
            })}
          </ul>
        ) : null}
        {des2 ? <p className="paraSection">{des2}</p> : null}
      </div>
    </div>
  );
}