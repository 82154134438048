import "./style.css";
import React, { useEffect } from "react";
import { Helmet } from 'react-helmet';
import ContactForm from "../common/contacrForm";
export default function ContactUs({ setSubFooter }) {
	const meta_tag="Contact us"
	useEffect(() => {
		window.scrollTo(0, 0);
		document.title = "Contact us";
		const metaKeywords = document.querySelector('meta[name="keywords"]');
		if (metaKeywords) {
		  metaKeywords.setAttribute('content', meta_tag);
		}
        const currentUrl = window.location.href;

        const metaTags = Array.from(document.getElementsByTagName('meta'));
    
        const ogUrlMeta = metaTags.find((meta) =>
          meta.getAttribute('property') === 'og:site_name'
        );
    
        if (ogUrlMeta) {
			ogUrlMeta.setAttribute("content",`${window.location.href}`);
        } else {
          const newOgUrlMeta = document.createElement('meta');
          newOgUrlMeta.setAttribute('property', 'og:site_name');
          newOgUrlMeta.content = currentUrl;
          document.head.appendChild(newOgUrlMeta);
        }
		setSubFooter(true);
	}, [meta_tag]);
	return (
		<>
			 <Helmet>
            <meta name="keywords" content={meta_tag}/>
            <meta property="og:title"  name="title" content="Contact us" />

			</Helmet>

			<div className="">
				<div className="bg-img-contactusSection"></div>
				<section className="">
					<div className="container mt-150px">
						<div className="row">
							<div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 contact-info-column text-center">
								<img
									className="headerImage"
									src="assets/images/contactus/icon3.png"
									alt="address-icon"
								/>
								<h4>Location</h4>
								<p className="fnt-17">
									<a
										target="__blank"
										href="https://goo.gl/maps/a2zU5SMYdbCDj8ac7"
										className="fnt-17"
									>
										Rawmart Material Solutions Pvt. Ltd Office Address: Third Floor Rutba Building Parsanees Colony Maharshi Nagar, near Panchami Restaurant Pune Maharashtra 411037
									</a>
								</p>
							</div>
							<div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 contact-info-column text-center">
								<img
									className="headerImage"
									src="assets/images/contactus/icon2.png"
									alt="phone-icon"
								/>
								<h4>Phone</h4>
								<p className="fnt-17">
									<a href="tel:+918308006002">+91 8308006002</a>
									<br />
									<a href="tel:+917009440394">+91 7009440394</a>
								</p>
							</div>
							<div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 contact-info-column text-center">
								<img
									className="headerImage"
									src="assets/images/contactus/icon1.png"
									alt="message-icon"
								/>
								<h4>Email</h4>
								<p className="fnt-17">
									<a href="mailto:sr@rawmart.in">sr@rawmart.in</a>
									<br />
									<a href="mailto:tac@rawmart.in">tac@rawmart.in</a>
									<br/>
									<a href="mailto:shrenik@rawmart.in">shrenik@rawmart.in</a>
								</p>
							</div>
						</div>
						<ContactForm />
					</div>
					<div className="location-map" id="map" style={{ height: '500px' }}>
						<iframe
							title="Location Map"
							src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3783.8158215354306!2d73.86347219999999!3d18.492!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTjCsDI5JzMxLjIiTiA3M8KwNTEnNDguNSJF!5e0!3m2!1sen!2sin!4v1688535490160!5m2!1sen!2sin"
							width="600"
							height="100%"
							style={{ border: 0 }}
							allowFullScreen=""
							loading="lazy"
							referrerPolicy="no-referrer-when-downgrade"
						></iframe>
					</div>

				</section>
			</div>
		</>
	);
}
