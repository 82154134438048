import { FeatureBox } from './featureBox';

export default function RawmartTechEcosystem() {
  let data = [
    {
      title: "Rawmart's as a Strategic Partner",
      des: `Rawmart's, as a specialized platform for raw material procurement, understands the challenges faced by SME's in sourcing quality materials at competitive prices. By establishing strategic partnerships with SME's, Rawmart's can leverage their network and industry expertise to offer tailored solutions to meet the unique requirements of SME's.`,
      img: "assets/images/tech_ecosystem/rawmarts_strategic.png",
    },

    {
      title: "Enhanced Efficiency and Cost Savings",
      des: `The Rawmart's ecosystem provides SME's with access to a wide network of suppliers, enabling them to streamline their procurement processes. With a centralized platform, SME's can efficiently compare prices, quality, and delivery options from multiple suppliers, ensuring cost savings and reducing procurement time.`,
      img: "assets/images/tech_ecosystem/enhanced_efficiency.png",
      // list: [
      //   "locate required raw materials",
      //   "book and pay through a single window interface",
      //   "access elaborate online and offline support",
      //   "discover profit and growth opportunities",
      // ],
    },

    {
      title: "Diverse Range of Raw Materials",
      des: ` Rawmart's ecosystem offers a comprehensive range of raw materials across various industries, addressing the diverse needs of SME's. Whether it's sourcing raw materials for manufacturing, construction, or service-oriented businesses, Rawmart's provides a one-stop solution for SME's, eliminating the need to search for suppliers individually.So that you can meet your production commitments and maintain smooth and hassle-free supply.`,
     
      img: "assets/images/tech_ecosystem/diverse_range.png",
    },

    {
      title: "Quality Assurance",
      des: `Rawmart's emphasizes quality control and ensures that the raw materials offered through their platform meet industry standards. By partnering with trusted suppliers and conducting quality checks, Rawmart's helps SME's mitigate risks associated with substandard or counterfeit raw materials, ensuring the production of high-quality goods and services.`,
      // des2: `Some of the domains we extensively cover include sales,
      //             marketing, supply chain, finance, human resource, IT. research
      //             & business analysis.`,
      img: "assets/images/tech_ecosystem/quality_assurance.png",
    },
    {
      title: "Technological Integration",
      des: `Rawmart's tech ecosystem is designed to empower SME's in India by leveraging digital solutions. The platform may incorporate features such as AI-driven supplier recommendations, automated order processing, real-time inventory tracking, and analytics tools. These technological advancements streamline the procurement process, improve decision-making, and enhance overall efficiency for SME's.`,
      img: "assets/images/tech_ecosystem/technological_integration.png",
    },
    {
      title: "Access to Financing and Credit Facilities",
      des: `Rawmart's can collaborate with financial institutions and offer SME's access to financing and credit facilities. This can help SME's overcome financial constraints and ensure a steady supply of raw materials, even during periods of limited cash flow.`,
      img: "assets/images/tech_ecosystem/access_financing.png",
    },
  ];
  return (
    <section className="home4-service-section">
      <div className="container">
        <div className="row ">
          <div className="head-section wdt-100 mb-55 centerData">
            <div className="col-lg-5 col-md-6 col-sm-8 col-xs-12">
              <h3 className="heading-h3 noMargin">
                <span className="clr-blue">RAWMART</span> Tech Ecosystem
              </h3>
            </div>
            <div className="col-lg-7 col-md-6 col-sm-12 col-xs-12">
              <p className="fnt-14">
                The Rawmart's ecosystem complements the Indian SME ecosystem by offering a range of benefits that attract SME's to partner with rawmart, making it larger than life and providing one-stop solutions for their raw material procurement needs. Additionally, Rawmart's tech ecosystem is designed to specifically help SME's in India. Let's explore these points in details:

              </p>
            </div>
          </div>
          <div className="col-md-12 row flexWrap">
            {data.map((i, j) => {
              return (
                <FeatureBox data={i} key={j} first={j === 0 ? true : false} />
              );
            })}
          </div>
          <div className='col-md-12' style={{backgroundColor:"#f2f2f2",borderRadius:"23px",boxShadow: "rgb(170, 170, 170) 1px 1px 7px",marginTop:"40px"
}}>
          <p className="fnt-14"
          style={{textAlign:"center",
                  fontWeight:"bold",padding:"15px"}}
          >By combining these elements, the Rawmart's ecosystem becomes an indispensable partner for SME's in India. It addresses their raw material procurement challenges, enhances efficiency, offers cost savings, ensures quality, and provides access to technological tools and financial support. Ultimately, Rawmart's plays a crucial role in supporting the growth and success of SME's within the larger Indian SME ecosystem.</p>
          </div>
        </div>
      </div>
    </section>
  );
}