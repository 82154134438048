export default function WhoAreSme() {
  return (
    <section className="bgSepration">
      <div className="container">
        <h3 className="heading-h3">
          Who Are <span className="clr-blue">SME's</span>
        </h3>
       
        <h6 className='subheading_h6'>The SME ecosystem in India encompasses a diverse range of industries and sectors. Through Rawmarts’ lens the ecosystem is:
        </h6>
        <div className="row centerdata rev-clm">
          
          <div className="col-lg-12 col-md-6 col-sm-12 col-xs-12 rev-clm-sub " style={{marginTop:"-55px"}}>

          
            <p className="fnt-14">
              <span className='clr-black'>Manufacturing Industries:</span> The manufacturing sector comprises a significant portion of the SME ecosystem in India. It includes various industries such as textiles, automobiles, chemicals, electronics, food processing, pharmaceuticals, and more.<br/>
                <span className='clr-black'>Construction Contractors:</span>Construction contractors play a crucial role in infrastructure development. They are involved in various construction activities, such as building residential and commercial properties, infrastructure projects, roads, bridges, and other civil engineering projects. </p>
         
          </div>
          <div className="col-lg-12 col-md-6 col-sm-12 col-xs-12 ">
            <div className="img mt-10 d-flex justify-content-center">
              <div className="" style={{display:"flex",justifyContent:"center",alignContent:"center"}}>
                <img src="assets/images/img2.jpg" className="img-responsive" alt="about" />
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
  );
}