export default function FabricationBox(data) {

  return (
    <div className="col-lg-4 col-md-4 col-sm-8 col-xs-12 img pad_zero cloneditem-1" style={{ padding: '15px' }}>
      <img
        className="img-full  customImage"
        src={`assets/images/fabricate_product/${data.data}.jpg`}
        alt="Project1"
      />

    </div>
  );
}
// export function FabricationBoxSPMs1(data) {
//   return (
//     <div className="col-lg-4 col-md-4 col-sm-8 col-xs-12 img pad_zero cloneditem-1" style={{ padding: '15px' }}>
//       <img
//         className="img-full  customImage"
//         src={`assets/images/fabricate_product/${data.data}.jpg`}
//         alt="Project1"
//       />
//     </div>
//   );
// }
export function FabricationBoxSPMs(data) {
  return (
    <>
        <div className="col-md-6 col-sm-9 img pad_zero cloneditem-1 " style={{ padding: '15px' }}>
          <img
            className="img-full  customImage"
            src={`assets/images/fabricate_product/${data.data}.jpg`}
            alt="Project1"
          />
        </div>
    </>
  );
}
