import React, { useState } from "react";
import ProductBox from "../home/productBox";
import Info from "../home/info";
import SolutionInfo from "./solutioninfo";
import DesignInfo from "./designinfo";
import ContactForm from "../common/contacrForm";
import { useEffect } from "react";
// import { Helmet } from 'react-helmet';
import "./style.css";
import { WhyRawmartBox } from "../home/whyRawmartBox";
import { EngineeringBox } from "./engineeringBox";
import { DesignBox } from "./designBox";
import FabricationBox, { FabricationBoxSPMs, FabricationBoxSPMs1, FabricationBoxSPMs2 } from "./fabrication_product_box";
import { Helmet } from "react-helmet";
export default function Solutions() {
	// const { innerWidth } = window;
	const [open, setOpen] = useState(false);
	const [subOpen, setSubOpen] = useState(false);
	const meta_tag ='Solve';

	useEffect(() => {
		window.scrollTo(0, 0);
		document.title = "Solutions";
		const metaKeywords = document.querySelector('meta[name="keywords"]');
		if (metaKeywords) {
		  metaKeywords.setAttribute('content', meta_tag);
		}
		const currentUrl = window.location.href;

        const metaTags = Array.from(document.getElementsByTagName('meta'));
    
        const ogUrlMeta = metaTags.find((meta) =>
          meta.getAttribute('property') === 'og:site_name'
        );
    
        if (ogUrlMeta) {
			ogUrlMeta.setAttribute("content",`${window.location.href}`);
        } else {
          const newOgUrlMeta = document.createElement('meta');
          newOgUrlMeta.setAttribute('property', 'og:site_name');
          newOgUrlMeta.content = currentUrl;
          document.head.appendChild(newOgUrlMeta);
        }
    //     const dynamicKeywords = 'Solve'; // Provide the dynamic keywords here

    // const metaKeywords = document.querySelector('meta[name="keywords"]');
    // if (metaKeywords) {
    //   metaKeywords.setAttribute('content', "Solve");
    // } else {
    //   const newMetaKeywords = document.createElement('meta');
    //   newMetaKeywords.setAttribute('name', 'keywords');
    //   newMetaKeywords.setAttribute('content', dynamicKeywords);
    //   document.head.appendChild(newMetaKeywords);
    // }

	}, [meta_tag]);
	let [activeClass, setActiveClass] = useState("sheet_metal");
	let [activeClass1, setActiveClass1] = useState("tmtRebears");
	let [activeClass2, setActiveClass2] = useState("pharma_product");

	// const procurement = [1, 2, 3, 4, 5, 6, 7, 8, 9];
	const aluminum = [1, 2, 3, 4, 5, 6, 7, 8, 9];
	const stainlesssteel = [1, 2, 3, 4, 5, 6, 7, 8];
	const tmtRebears = [1, 2, 7, 4, 5, 6];
	const procurement = [1, 2, 3, 4, 5, 6, 7, 8, 9];
	const otherproduct = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
	const polymer = [1, 2, 3, 4];
	const mildsteel = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
	const pharma_product = [18, 15, 6, 17, 2, 21, 22, 23, 24];
	const gangways_for_indian_railways = [1, 2, 3];
	const material_handeling_equipments = [1, 2, 3, 4, 5];
	const precision_manufacturing = [2, 6, 7, 8];
	const spms1 = [1, 2, 4];
	const spms2 = [1, 2];
	const CommonClass = "tab-pane hide";
	let data = {

		procurement: {
			last: true,
			products: [
				{
					productName: "Extensive Product Range:",
					para: [
						`At Rawmart, we currently offer a wide selection of high-quality raw materials, including ferrous and non-ferrous materials .We take pride in our commitment to delivering top-notch raw materials of uncompromising quality.
						`,
					],
				},

				{
					productName: "Expertise and Guidance:",
					para: [
						`Our team of experienced professionals possesses extensive knowledge in the procurement of raw materials, specifically MS and SS. We provide informed guidance to help you make the right choices based on your specific requirements.
						`,
					],
				},
				{
					productName: "Reliable Supply Chain:",
					para: [
						`With strong relationships with manufacturers and suppliers, we maintain a robust and dependable supply chain, ensuring a steady availability of raw materials.`,
					],
				},
				{
					productName: "Customer-Centric Approach:",
					para: [
						`We prioritize customer satisfaction and go above and beyond to understand your needs. Our personalized service and tailored solutions ensure a positive procurement experience.`,
					],
				},
				{
					productName: "Geographical Reach: ",
					para: [
						`We currently serve in five states, namely Maharashtra, Gujarat, Madhya Pradesh, Goa, Karnataka, and Uttar Pradesh. As part of our expansion plans, we are aiming to extend our operations to additional regions, including Punjab, Rajasthan, Tamil Nadu, Telangana, and more.
						`,
					],


				},
				{
					productName: "Value-Added Services: ",
					para: [
						`In addition to providing raw materials, we offer value-added services such as cutting, bending, and machining, tailored to the specific requirements of our customers.
						`,
					],


				},
				{
					productName: "Future Expansion: ",
					para: [
						`As part of our growth strategy, we are planning to expand into additional verticals such as Bitumin, furnace oils, gases,Non ferrous , petro products, polymer  and other  materials. These additions will enhance our product range and allow us to cater to a wider range of industry needs.
						`,
					],
				},
			],
			images: [
				"assets/images/products/product_sub/ms-img1.jpg",
				"assets/images/products/product_sub/ms-img2.jpg",
				"assets/images/products/product_sub/ms-img3.jpg",
				"assets/images/products/product_sub/ms-img4.jpg",
				"assets/images/products/product_sub/ss-img5.jpg",
				"assets/images/products/product_sub/ss-img6.jpg",
				"assets/images/products/product_sub/ss-img7.jpg",
				"assets/images/products/product_sub/ss-img8.jpg",
				"assets/images/products/product_sub/ss-img9.jpg",

			],

		},
		sheet_metal: {
			last: true,
			products: [
				{
					productName: "Laser cutting",
					para: [
						`Our state-of-the-art laser cutting technology allows us to precisely and cleanly cut sheet metal materials along programmed paths. This ensures the highest level of accuracy and quality in the finished components.
						`,
					],
				},
				{
					productName: "Heavy fabrication",
					para: [
						` We specialize in handling large and sturdy sheet metal components designed for heavy-duty applications. Our team excels in welding, bending, and assembling these robust structures, ensuring their strength and reliability.
						`,
					],
				},
				{
					productName: "Stamping",
					para: [
						`Through the application of high-pressure forces, we expertly transform sheet metal into specific shapes or patterns. Our specialized dies and punches enable us to achieve complex geometries with unparalleled precision.`,
					],
				},
				{
					productName: "Deep Drawn products",
					para: [
						`With a deep understanding of stretching and compressing forces, we can form sheet metal into deep, cylindrical, or box-like shapes that possess high aspect ratios. This capability is essential for various industries, including automotive and aerospace.
						`,
					],
				},
				{
					productName: "Wire cutting:",
					para: [
						`By utilizing advanced wire cutting techniques, we can erode and remove material from sheet metal, enabling the creation of intricate shapes and contours with micron-level accuracy.
						`,
					],
				},
				{
					productName: "Punching",
					para: [
						`Our rapid and precise punching processes allow us to create holes, notches, and complex geometries in sheet metal components. This adds functionality and versatility to the finished products.`,
					],
				},
				{
					productName: "CNC Bending",
					para: [
						`Leveraging computer-controlled press brakes, we achieve unparalleled accuracy in deforming sheet metal along predetermined bending lines. This guarantees the desired angles and geometries are met consistently.
						`,
					],
				},
				{
					productName: "Water jet cutting",
					para: [
						`Our high-pressure water jet cutting system, combined with abrasive particles, enables us to achieve precise and intricate shapes in sheet metal components. This process is ideal for materials that are sensitive to heat.`,
					],
				},
				{
					productName: "Plasma cutting",
					para: [
						`By utilizing a high-velocity jet of ionized gas, we can locally melt and blow away sheet metal, resulting in fast and efficient cutting with minimal heat-affected zones.`,
					],
				},
			]
		},
		machining: {
			last: true,
			products: [
				{
					productName: "Machining Capabilities:",
					para: [
						`VMC (Vertical Machining Center): Equipped with computer-controlled milling machines featuring vertically oriented spindles for precise material removal from workpieces using rotating cutting tools.
						`,
						`CNC (Computer Numerical Control): Leveraging the power of CNC machining to transform raw materials, including pre-shaped or cylindrical bar stocks, into finished components with exceptional accuracy and a wide range of sizes and shapes.
						`,
						`HMC (Horizontal Machining Center): CNC milling machines with horizontally oriented spindles and rotary tables for performing complex milling and drilling operations on workpieces with high precision and efficiency.
						`,
						`VTL (Vertical Turret Lathe): Specializing in heavy-duty turning operations on large workpieces to ensure superior dimensional accuracy and surface finish.
						`,
						`Heavy Machining: Expertise in handling large-scale components that require specialized equipment and techniques while ensuring utmost precision and quality even with challenging materials.
						`,
						`Critical Machining: Proficiency in precision machining of components with strict dimensional tolerances, intricate features, or critical functionality using advanced tooling and measurement systems for the highest level of accuracy.`,
						`Turnmill: Combining turning and milling operations in a single setup to enable efficient production of complex parts with minimal repositioning, ensuring optimal productivity and accuracy.`,
						`Milling: Removing material from workpieces using rotary cutters to achieve precise shapes, dimensions, intricate details, and smooth surface finishes.
						`
					],
				},
			]
		},
		casting: {
			last: true,
			products: [
				{
					productName: "Casting Capabilities:",
					para: [
						`Investment Casting: Creating wax or plastic patterns, coating them with a refractory ceramic material, and then pouring molten metal into the resulting mold cavity to produce complex and highly detailed castings.`,
						`Sand Casting: Utilizing specially prepared sand molds and pouring molten metal into the created cavity to produce large and intricate components with versatility and cost-effectiveness.
						`,
						`Pressure Die Casting: Injecting molten metal into reusable metal molds under high pressure to manufacture intricate and lightweight castings with tight tolerances.
						`,
						`Gravity Die Casting: Pouring molten metal into metal molds using the force of gravity to obtain castings with excellent dimensional accuracy and surface finish.`
					],
				},
			]
		},
		forging: {
			last: true,
			products: [
				{
					productName: "Forging Capabilities:",
					para: [
						`Open-Die Forging: Shaping large and customized components using compressive forces between flat or slightly shaped dies, suitable for both ferrous (e.g., stainless steel, mild steel) and non-ferrous materials.
						`,
						`Closed-Die Forging: Creating complex and intricately shaped components with high precision and strength using specially designed dies with pre-formed cavities, applicable to ferrous materials (e.g., stainless steel, mild steel) and non-ferrous materials (e.g., brass, bronze).
						`,
						`Upset Forging: Producing components with larger cross-sections and improved mechanical properties by applying compressive forces to the ends of the workpiece, suitable for both ferrous and non-ferrous materials.`,
						`Drop Forging: Utilizing a hammer or press to deliver a sudden impact to the workpiece, shaping it into high-strength components, commonly used with ferrous materials like stainless steel and carbon steel.
						`,
						`Press Forging: Applying continuous pressure using hydraulic or mechanical presses for controlled deformation and precise shaping of components, compatible with both ferrous and non-ferrous materials.
						`,
						`Cold Forging: Shaping components at or near room temperature to achieve improved dimensional accuracy, enhanced surface finish, and increased strength through work hardening. Suitable for both ferrous and non-ferrous materials.
						`
					],
				},
			]
		},
		spm: {
			last: true,
			products: [
				{

					productName: "Customized Solutions:",
					para: [
						`RAWMART designs tailored SPMs to meet specific manufacturing needs, optimizing productivity, efficiency, and quality.
						`,
					],
				},
				{

					productName: "Conceptualization and Design:",
					para: [
						`Experienced engineers and designers create detailed SPM designs using cutting-edge software and tools.`,
					],
				},
				{

					productName: "Innovative Approaches:",
					para: [
						` RAWMART incorporates advanced technologies and creative problem-solving to develop innovative SPM solutions.`,
					],
				},
				{

					productName: "Integration with Existing Systems:",
					para: [
						` SPMs are seamlessly integrated into existing manufacturing setups, enhancing the overall production system.
						`,
					],
				},
				{

					productName: "Precision and Reliability:",
					para: [
						` SPM designs prioritize precision, reliability, and repeatability using robust mechanisms and accurate control systems.
						`,
					],
				},
				{

					productName: "Optimization and Efficiency:",
					para: [
						` Designs are optimized for maximum efficiency, minimizing waste, and improving operational productivity.`,
					],
				},
				{

					productName: "Testing and Validation:",
					para: [
						` Rigorous testing and validation ensure SPM designs meet required specifications for functionality, safety, and reliability.
						`,
					],
				},
			]
		},
		aluminum: {
			para: [
				`One of the largest integrated primary sources of aluminium are in India. We have dedicated sources to find the best extrusions from various aluminium companies at the most reliable and reasonable prices.
        Our strength in aluminium products differentiat`,
				`Our strength in aluminium products differentiates us from our competitors. A significant portion of Rawmart’s sales comes from value-added products. Rawmart caters to numerous markets and provides for all industrial and infrastructural requirements.`,
				`Our finished products include primary aluminium in the form of ingots, billets and wire rods and value-added products such as rolled products, extrusions and foils.`,
				`Our team is well equipped to compare conversion costs and quality of the product supplied; a vital service that makes Rawmart a leading supplier in the extrusions industry in India. We also offer a wide range of alloys including hard alloys and special alloys for defence and space sectors.`,
			],
			pPara: "List of Aluminium Products we Supply: ",
			listOfProduces: [
				"Aluminium Angles.",
				"Aluminium Channels.",
				"Aluminium Flat Bars.",
				"Aluminium Hexagonal Bars.",
				"Aluminium Rectangular Tubes.",
				"Aluminium Round Tubes.",
				"Aluminium Square Tubes.",
				"Aluminium IPS Tubes.",
				"Aluminium Rods.",
				"Aluminium Alloy Ingots.",
				"Aluminium T-Sections.",
				"Aluminium Heat Sinked",
				"Aluminium Machine Components",
			],
			showBradInSlider: 2,
			brandLogo: [
				"assets/images/brandlogo/hindalco.jpg",
				"assets/images/brandlogo/nalco.jpg",
			],

			images: [
				"assets/images/products/al-img1.jpg",
				"assets/images/products/al-img2.jpg",
				"assets/images/products/al-img3.jpg",
				"assets/images/products/al-img4.jpg",
				"assets/images/products/al-img5.jpg",
				"assets/images/products/al-img6.jpg",
				"assets/images/products/al-img7.jpg",
				"assets/images/products/al-img8.jpg",
				"assets/images/products/al-img9.jpg",
			],
		},
		stainlesssteel: {
			para: [
				`Stainless Steel is widely known in the industrial sector as the unmatched ‘green material’ for all the right reasons. With actual recovery rate touching 100%, it is completely recyclable, corrosion resistant, fire resistant, super sturdy, aesthetic and versatile. Stainless Steel is generally seen as a family of chromium-containing alloys.`,
				`Stainless Steel plays a major role in the hygienic preparation or storage of almost everything we eat or drink. All modern vehicles boast stainless steel components in critical areas. Stainless steel is used increasingly in a large scale in Railways, Pharmaceuticals industry, FMCG Food Processing, Aeronautics and in several other regions.`,
				`Rawmart supplies a wide range of hot rolled/cold rolled coils and cut lengths (sheets) in austenitic, ferritic and martensitic grades of stainless-steel conforming to ASTM, BIS and various other specifications in a variety of sizes and finishes of several grades such as 200, 300 and 400.`,
			],
			pPara: "List of Stainless-steel products we supply :",
			listOfProduces: [
				"HR Coils / Sheets.",
				"CR Coils / Sheets.",
				"Pipes.",
				"Tubes.",
				"Flats.",
				"Rounds.",
			],
			showBradInSlider: 2,
			brandLogo: [
				"assets/images/brandlogo/jsl.png",
				"assets/images/brandlogo/Sail.png",
			],
			images: [
				"assets/images/products/ss-img1.jpg",
				"assets/images/products/ss-img2.jpg",
				"assets/images/products/ss-img3.jpg",
				"assets/images/products/ss-img4.jpg",
				"assets/images/products/ss-img5.jpg",
				"assets/images/products/ss-img6.jpg",
				"assets/images/products/ss-img7.jpg",
				"assets/images/products/ss-img8.jpg",
			],
		},
		mildsteel: {
			para: [
				`Mild steel, also known as low carbon steel, utilizes only a minute amount of carbon. The percentage of carbon may vary from 0.05% to 0.25%. The insignificant number of alloying elements helps mild steel to be affordable relatively when done in comparison with other steels. The machinability, affordability, and weldability are making it a popular steel choice for users.
`,
				`High affordability topped with outstanding properties gives mild steel an inalienable place in the construction industry. Buildings, bridges, pipelines, gates, fencing etc. are only a few to mention. It is also used in many industries like shipbuilding, galvanizing pots, industrial flooring, etc. `,
				`Rawmart as a reliable supplier is offering the high quality of special mild steel products to the buyers with different specifications that are available in different material grades, width, thickness, length, etc.
`,
				`Rawmart deals in both Primary and Secondary Brands.`,
			],
			pPara: "List of Mild Steel Products which we supply:",
			headingTitle1: "Structural Steel Products",
			listOfProduces: [
				"Angle",
				"Channel",
				"Flats",
				"Round Bars",
				"Square Bars",
				"Beams",
				"Wire Rods",
				"Tubes",
				"Pipe",
			],
			// headingTitle2: "TMT REBARS",

			// listOfProduces2: [
			// 	"FE500",
			// 	"FE550",
			// 	"FE500D",
			// 	"FE550D",
			// 	"Epoxy Coated",
			// 	"CRS TMT",
			// ],
			headingTitle3: "Hot Rolled Products",
			listOfProduces3: [
				"HR Coils",
				"HR Sheets",
				"HR Plates",
				"HRPO Sheets / Coils",
				"HRSPO Sheets / Coils",
			],
			headingTitle4: "Cold Rolled Products",
			listOfProduces4: ["CR Coils ", "CR Sheets"],
			showBradInSlider: 2,
			brandLogo: [
				"assets/images/brandlogo/jsw.png",
				"assets/images/brandlogo/tatasteel.png",
				"assets/images/brandlogo/neosteel.jpeg",
				"assets/images/brandlogo/tatatiscon.png",
				"assets/images/brandlogo/Sail.png",
				"assets/images/brandlogo/rinl.png",
				"assets/images/brandlogo/essar.jpg",
				"assets/images/brandlogo/uma.png",
				"assets/images/brandlogo/rajuri.jpeg",
				"assets/images/brandlogo/kamdhenu.jpg",
				"assets/images/brandlogo/polad.jpg",
				"assets/images/brandlogo/gkispat.png",
				"assets/images/brandlogo/balaji.png",
				"assets/images/brandlogo/posco.png",
				"assets/images/brandlogo/uttam.png",
				"assets/images/brandlogo/jspl.png",
			],
			images: [
				"assets/images/products/ms-img1.jpg",
				"assets/images/products/ms-img2.jpg",
				"assets/images/products/ms-img3.jpg",
				"assets/images/products/ms-img4.jpg",
				"assets/images/products/ms-img5.jpg",
				"assets/images/products/ms-img6.jpg",
				"assets/images/products/ms-img7.jpg",
				"assets/images/products/ms-img8.jpg",
				"assets/images/products/ms-img9.jpg",
				"assets/images/products/ms-img10.jpg",
				"assets/images/products/ms-img11.jpg",
			],
		},
		polymer: {
			para: [
				`Engineering polymers are types of plastic materials. However, unlike commodity plastics they have special properties like high dimensional stability, good strength, heat and chemical resistance, which makes them a widely used substitute for metals across industries.`,
				`Consumer Durables and White Goods/ Appliances market, automotive and transportation industries are the biggest end-users of Engineering Plastics.`,
				`Well established applications of polymers in construction include areas such as flooring, windows, cladding, rainwater, pipes, membranes, seals, glazing, insulation and signage. With thousands of commercially available polymers, new applications are continuously emerging.`,
				``,
			],
			pPara:
				"Rawmart supplies a variety of Engineering Polymers at most competitive rates across the country:",
			listOfProduces: [
				"Acrylonitrile butadienestyrene(ABS)",
				"Expanded Polystyrene",
				"High Impact Polystyrene",
				"Nylon 66",
				"Nylon-6",
				"Polymethyl methacrylate(PMMA)",
				"Polycarbonate",
				"General Purpose Polystyrene(GPPS)",
			],
			images: [
				"assets/images/products/pl-img1.jpg",
				"assets/images/products/pl-img2.jpg",
				"assets/images/products/pl-img3.jpg",
				"assets/images/products/pl-img4.jpg",
			],
		},
		otherstel: {
			last: true,

			products: [
				{
					productName: "Galvanized Products: ",
					para: [
						`Galvanized Steel are basically sheets which have been coated with zinc. This includes a hot dip galvanized and electro galvanized steel sheets. The zinc coating provides a continuous barrier which does not allow moisture and oxygen to reach the steel.
`,
						`The applications of Galvanised Steel are numerous, especially in fabrication and manufacturing environments. Construction workers mostly use Galvanised Coils for Roofing applications. They are also used as sheet metal brakes in buildings. 
`,
						`The biggest use of GI Sheets is for construction projects in homes, offices, and factories. They resist corrosion, heat, and are easily installable, making them best suited for roofing. They are also used in the production of corrugated panels, drywall panel profiles, ventilation systems, etc.
`,
					],
					showBradInSlider: 3,
					brandLogo: [
						"assets/images/brandlogo/uttam.png",
						"assets/images/brandlogo/jsw.png",
						"assets/images/brandlogo/amns.png",
						"assets/images/brandlogo/tatabsl.png",
					],
				},

				{
					productName: "PPGL",
					para: [
						`PPGL Sheets / Coils are prepared by combining the strength of steel with anti-corrosive capabilities of a metal coating like Zinc or Aluminium. The resultant product then has to be coated with organic substances.`,
						`It is exceptionally resistant to weather and environmental degradations and can be punched, pressed, roll formed or joined in any way possible per industrial requirement.`,
						`The Usage of PPGL Coil spans the Construction and Home Appliance Manufacturing Industries. PPGL is used as a great alternative for wood owing to benefits such as avoidance of discoloration, and weather tolerance. Automobile Manufacturers and Producers employ PPGL Coil. Coal Mines also see sufficient use of this product. Other Industries include Light Manufacturing, Medicine Industry, Food Industry and Furniture Manufacturing.`,
						`PPGL Sheets see extensive use in Building and Roofing Applications. They are also used in making certain internal automotive parts and appliances. Computer cases, wooden panels, gutters, pipe, furniture, outdoor cabinetry also need PPGL Sheets for manufacture. Architects, Building Owners, and Interior Decorators are mostly in need of PPGL sheets.
                         Rawmart deals in almost all dimensions in PPGL Sheets/Coils ranging from 0.25mm to 0.60mm.`,
					],
					pPara: "Galvanized products include :",
					listOfProduces: ["GI Coils", "GI Sheets"],
					showBradInSlider: 2,
					brandLogo: [
						"assets/images/brandlogo/tatadurashine.png",
						"assets/images/brandlogo/jswcolor.png",
					],
				},

				{
					productName: "PPGI",
					para: [
						`PPGI is pre-painted galvanised iron, also known as pre-coated steel, coil coated steel, color coated steel etc., typically with a hot dip zinc coated steel substrate. ... PPGI refers to factory pre-painted zinc coated steel, where the steel is painted before forming, as opposed to post painting which occurs after forming.`,
						`PPGI Coils are used mainly in the Automobile and Construction industry. It is commonly used for making building material such as roofing sheets, roofing tiles, sandwich panels etc. The Transportation, Manufacturing Industry, Light Industry, manufacture of office furniture, manufacture of household electrical appliances etc. also make use of PPGI Coils.`,
						`The biggest use of PPGI Steel Sheets is as Roofing Sheets for Buildings and in Construction Projects. Other places where they are used are in Side Walls, Partitions, Panels, Valley Gutters, Louvers, False Ceilings, Partition Walls and Ducks, Rolling Shutters, Highway Bumpers, Slotted Angles, and Paint-Coated products.`,
					],

					showBradInSlider: 2,
					brandLogo: [
						"assets/images/brandlogo/amns.png",
						"assets/images/brandlogo/uttam.png",
						"assets/images/brandlogo/asian.png",
					],
				},

				{
					productName: "Chequered Plate",
					para: [
						`Chequer plate also called chequered plate is a plate with a regular pattern of projections on one side, showing diamond or other shapes, with the reverse side being smooth. It can be in the form of tear drop mild steel chequered plates, diamond shaped mild steel chequered plates or embossed mild steel plates.`,
						`Steel chequered plates are extensively used as floor coverings in marine, stairways, transportation equipment and for general structural purposes.`,
					],

					showBradInSlider: 2,
					brandLogo: [
						"assets/images/brandlogo/Sail.png",
						"assets/images/brandlogo/jsw.png",
						"assets/images/brandlogo/jspl.png",
						"assets/images/brandlogo/tatasteel.png",
					],
				},

				{
					productName: "BQ Plate",
					para: [
						`BQ PLATES the boiler quality and pressure vessel steel plates are primarily intended for use in welded pressure vessels where notch toughness is important. These grades cover a range of tensile strengths from 450 - 620 MPa and this versatility explains much of the specification’s popularity.`,
						`Boiler steel plate is one of the most important materials in boiler manufacturing. It mainly refers to hot-rolled special carbon steel and low alloy used for manufacturing important parts such as boiler shell, drum, header cover, and hanger. Heat resistant steel medium and thick steel plate material.`,
					],
				},

				{
					productName: "DI Pipes",
					para: [
						`Ductile iron pipe (DI pipe) is created from ferric scrap metal typically containing greater than 98 percent recycled content.`,
						`Back in 1948, it was observed that addition of magnesium in the molten pig iron could significantly alter the graphite structure in Cast Iron. In the initial microstructure of grey cast iron, graphite was in the form of flakes but assumed a spheroidal/nodular shape after the addition of Magnesium. The mechanical property of ductile iron as compared to grey cast iron show superior tensile strength, yield strength, ductility and impact resistance. The use of ductile iron has grown rapid rate over the past 50 years. The much-improved properties were recognised to be of particular benefit to the centrifugal pipe industry and most of the pipe production is now in Ductile Iron.`,
						`The range comprises of Socket and Spigot end Ductile Iron Pipes suitable for Push-on Joints as per IS 8329, BS EN 545, BS EN 598 & ISO 253`,
					],

					list: true,
					listItem: [
						"Water Transmission and Distribution",
						"Gravity Sewers and Pressure Mains",
						"Fire Protection Systems",
					],
				},
			],

			images: [
				"assets/images/products/ot-img1.jpg",
				"assets/images/products/ot-img2.jpg",
				"assets/images/products/ot-img3.jpg",
				"assets/images/products/ot-img4.jpg",
				"assets/images/products/ot-img5.jpg",
				"assets/images/products/ot-img6.jpg",
				"assets/images/products/ot-img7.jpg",
				"assets/images/products/ot-img8.jpg",
				"assets/images/products/ot-img9.jpg",
				"assets/images/products/ot-img10.jpg",
			],
		},
		tmtRebears: {
			para: [
				`TMT steel bars are generally used material in construction for enhanced protection against earthquake and another type of natural disasters. To ensure safety for your structure TMT steel bars should be resistant to corrosion which helps to obtain a longer life to your structure.`,
				`TMT steel bars come in different diameters like 8mm, 10mm, 12mm, 16mm, 20mm, 25mm, 32mm, 36mm.`,
				`8mm-10mm size bars are used in Slabs and Stair ups, which serves as a load bearing member in slab homes.`,
				`12mm-25mm size bars are used in Beams & Columns, to make them withstand external loads.`,
				`32mm-36mm size bars are used in the construction of very complex projects like dams, bridges.`,
			],
			pPara: "TMT Bar:",
			listOfProduces: [
				"FE500",
				"FE550",
				"FE500D",
				"FE550D",
				"Epoxy Coated",
				"CRS TMT",
			],
			showBradInSlider: 2,
			brandLogo: [
				"assets/images/brandlogo/tatatiscon.png",
				"assets/images/brandlogo/jsw.png",
				"assets/images/brandlogo/Sail.png",
				"assets/images/brandlogo/jsw2.png",
				"assets/images/brandlogo/rajuri.jpg",
			],

			images: [
				"assets/images/products/tmt1.jpg",
				"assets/images/products/tmt2.jpg",
				"assets/images/products/tmt7.jpg",
				"assets/images/products/tmt4.jpg",
				"assets/images/products/tmt5.jpg",
				"assets/images/products/tmt6.jpg",
			],
		},

		pharma_product: {
			images: [
				"assets/images/fabricate_product/pharma18.jpg",
				"assets/images/fabricate_product/pharma15.jpg",
				"assets/images/fabricate_product/pharma6.jpg",
			],
		},
		gangways_for_indian_railways: {
			images: [
				"assets/images/fabricate_product/railway1.jpg",
				"assets/images/fabricate_product/railway2.jpg",
				"assets/images/fabricate_product/railway3.jpg",

			],
		},
		material_handeling_equipments: {
			images: [
				"assets/images/fabricate_product/material1.jpg",
				"assets/images/fabricate_product/material2.jpg",
				"assets/images/fabricate_product/material3.jpg",
				"assets/images/fabricate_product/material4.jpg",
				"assets/images/fabricate_product/material5.jpg",

			],
		},
		precision_manufacturing: {
			images: [
				"assets/images/fabricate_product/precision6.jpg",
				"assets/images/fabricate_product/precision2.jpg",
				"assets/images/fabricate_product/precision7.jpg",
				"assets/images/fabricate_product/precision8.jpg",

			],
		},
		spms1: {
			images: [
				"assets/images/fabricate_product/solution_design1.jpg",
				"assets/images/fabricate_product/solution_design2.jpg",
				"assets/images/fabricate_product/solution_design4.jpg",


			]
		},
		spms2: {
			images: [
				"assets/images/fabricate_product/engineering_solu1.jpg",
				"assets/images/fabricate_product/engineering_solu2.jpg",

			],
		}
	};

	let engineering_data = [
		{
			des:
				`we provide compact, end-to-end engineering solutions for manufacturing challenges. With a focus on engineering excellence, we revolutionize traditional processes, ensure seamless integration, and deliver tailored solutions that surpass expectations. Choose Rawmart as your trusted partner for streamlined, high-quality manufacturing solutions.`,
		},
		{
			des:
				`Quality is our priority. We implement meticulous engineering processes, rigorous audits, and continuous monitoring to deliver fabricate_product that exceed industry standards. Through thorough inspection and testing, we ensure a flawless end product that meets exact specifications.`,
		},
		{
			des:
				`Our commitment to cost-effective solutions is unwavering. By optimizing resource allocation, minimizing waste, and embracing lean manufacturing principles, we provide competitive pricing without compromising engineering quality.`,
		},
		{
			des:
				`By simplifying the production process. With our engineering expertise and proven track record, we are the trusted partner for reliable, efficient, and high-quality manufacturing solutions.`,
		},
		{
			des:
				`Choose Rawmart for complete Design to Order solutions. We empower manufacturing companies to overcome engineering challenges and achieve unparalleled success. Let us turn your engineering vision into reality, delivering excellence at every stage.`,
		},

	]
	let design_data = [
		{
			title: "RAWMART: Engineering Solutions Beyond Boundaries",
			des:
				`At RAWMART, we excel in engineering solutions that go beyond expectations. Our brilliant team thrives on challenges, delivering groundbreaking and larger-than-life results. With unwavering commitment to excellence, we bring unmatched value to every project.
						`,
		},

		{
			title: "Reverse Engineering: Unveiling Innovation Secrets",
			des:
				`Unlocking the secrets of innovation, our cutting-edge technologies and expertise enable us to dissect and analyze existing products with precision. We breathe new life into their DNA, transcending limitations for redesign, replication, or enhancement.
						`,

		},
		{
			title: "Design to Order: Where Imagination Meets Mastery",
			des:
				`Imagination knows no bounds at RAWMART. Our visionary engineers push the boundaries of innovation, transforming your dreams into tangible reality. With mind-bending CAD software like Catia, Creo, Ansys, and SolidWorks, we craft intricate designs that dance with grace and precision.
						`,

		},
		{
			title: "Jigs and Fixtures: Precision for Manufacturing Marvels",
			des:
				`Specializing in compact jigs and fixtures, we optimize space utilization without sacrificing functionality or precision. Our innovative techniques, lightweight materials, and modular components deliver streamlined and efficient tooling solutions. Minimal shop floor space, maximum strength, and performance.
						`,

		},

		{
			title: "CAD Software Proficiency: Unleashing the Digital Frontier",
			des:
				`Our highly skilled engineers utilize industry-leading software such as Catia, Creo, Ansys, and SolidWorks to create precise and intricate 2D and 3D models. With their expertise, they bring your design concepts to life through advanced simulations and analyses, allowing us to visualize and refine your ideas before any physical construction takes place. By harnessing the full potential of these powerful software tools, we ensure that your projects exceed expectations and push the boundaries of imagination.
						`,



		},
		{
			title: "Process Testing and Design Solutions: Forging the Path to Manufacturing Excellence					",
			des:
				`We are dedicated to achieving manufacturing excellence, leaving no aspect unexamined. Our commitment to perfection drives us to deeply understand your production processes. Through meticulous analysis and optimization, we identify and address inefficiencies and bottlenecks that hinder your progress. Our ingenious engineering minds pave the way for unparalleled productivity, cost reduction, and process efficiency, ensuring a seamless and unstoppable journey towards your success.
						`,



		},
		{
			title: "Future Expansion: ",
			des:
				`As part of our growth strategy, we are planning to expand into additional verticals such as Bitumin, furnace oils, gases,Non ferrous , petro products, polymer  and other  materials. These additions will enhance our product range and allow us to cater to a wider range of industry needs.
						`,

		},
	]
	function changeProducts(e) {
		setActiveClass(e.target.id);
	}
	function changeProducts1(e) {
		setActiveClass1(e.target.id);
	}
	function changeProducts2(e) {
		setActiveClass2(e.target.id);
	}
	const handleManufacturingClick = () => {
		setSubOpen(!subOpen);
		setOpen(false); // Close the main navigation
	};
	
    return (
        <>
		<Helmet>
			<meta name="keywords" content={meta_tag} />
			<meta property="og:title"  name="title" content="Solution" />

			</Helmet>
    
			<section className="home4 recent-project-section pad100-top projectsec1" style={{ marginTop: "40px" }}>

				<section style={{}}>
					<div className="">
						<div className="row">
							<div className="container">
								<h3
									className="white-color h3Heading heading-h3"
									style={{ marginBottom: "20px", }}
								>
									<span className="clr-blue" style={{ color: "#543e8f" }}>Manufacturing  </span>
								</h3>

								<p style={{ fontSize: "16px", marginBottom: "20px" }}
								>
									RAWMART is a leading provider of comprehensive engineering solutions and manufacturing processes across India. Our expertise spans various disciplines, ensuring we meet the unique needs of our clients with utmost precision and efficiency.

								</p>
							</div>
							<ul className="nav nav-tabs" role="tablist" onClick={changeProducts}>
								<li
									role="presentation"
									className={activeClass === "sheet_metal" ? "active" : ""}
								>
									<div className="" id="sheet_metal">
										Sheet Metal Fabrication
									</div>
								</li>
								<li
									role="presentation"
									className={activeClass === "machining" ? "active" : ""}
								>
									<div className="" id="machining">
										Machining
									</div>
								</li>
								<li
									role="presentation"
									className={activeClass === "casting" ? "active" : ""}
								>
									<div className="" id="casting">
										Casting
									</div>
								</li>
								<li
									role="presentation"
									className={activeClass === "forging" ? "active" : ""}
								>
									<div className="" id="forging">
										Forging
									</div>
								</li>
								<li
									role="presentation"
									className={activeClass === "spm" ? "active" : ""}
								>
									<div className="" id="spm">
										Special Purpose Machines
									</div>
								</li>


							</ul>

						</div>


					</div>

					<div className="container">
						<div
							className={
								activeClass === "sheet_metal"
									? CommonClass + " show active"
									: CommonClass
							}
						>
							<p style={{
								marginTop: "22px",
								fontSize: "15px",
								fontWeight: "bold",
								fontFamily: "'Nexa'!important"
							}}>Sheet Metal Fabrication: In the realm of sheet metal fabrication, we offer a wide array of advanced processes that deliver exceptional results: </p>
							<div style={{ display: "flex", justifyContent: "center", paddingTop: "20px", alignItems: "center", margin: "0 auto" }}>

								<img src="/assets/images/img/sheet_metal_new.jpg" />
							</div>
							<Info info={data["sheet_metal"]} />
						</div>
						<div
							className={
								activeClass === "machining"
									? CommonClass + " show active"
									: CommonClass
							}
						>
							<p style={{
								marginTop: "22px",
								fontSize: "15px",
								fontWeight: "bold",
								fontFamily: "'Nexa'!important"
							}}>In the field of machining, we provide a diverse range of cutting-edge processes that yield outstanding outcomes</p>
							<div style={{ display: "flex", justifyContent: "center", paddingTop: "20px", alignItems: "center", margin: "0 auto" }}>

								<img src="/assets/images/img/machining.jpg" />
							</div>
							<SolutionInfo info1={data["machining"]} />
						</div>
						<div
							className={
								activeClass === "casting"
									? CommonClass + " show active"
									: CommonClass
							}
						>
							<p style={{
								marginTop: "22px",
								fontSize: "15px",
								fontWeight: "bold",
								fontFamily: "'Nexa'!important"
							}}>In the realm of casting facilities, we provide a broad range of advanced capabilities that deliver exceptional results</p>
							<div style={{ display: "flex", justifyContent: "center", paddingTop: "20px", alignItems: "center", margin: "0 auto" }}>
								<img src="/assets/images/img/casting.jpg" />
							</div>

							<DesignInfo info2={data["casting"]} />
						</div>
						<div
							className={
								activeClass === "forging"
									? CommonClass + " show active"
									: CommonClass
							}
						>
							<p style={{
								marginTop: "22px",
								fontSize: "15px",
								fontWeight: "bold",
								fontFamily: "'Nexa'!important"
							}}>Our expertise lies in forging, where we leverage a diverse repertoire of advanced techniques to deliver outstanding results. </p>
							<div style={{ display: "flex", justifyContent: "center", paddingTop: "20px", alignItems: "center", margin: "0 auto" }}>
								<img src="/assets/images/img/forging.jpg" />
							</div>
							<Info info={data["forging"]} />

						</div>
						<div
							className={
								activeClass === "spm"
									? CommonClass + " show active"
									: CommonClass
							}
						>
							<p style={{
								marginTop: "22px",
								fontSize: "15px",
								fontWeight: "bold",
								fontFamily: "'Nexa'!important"
							}}>Within our SPM offerings, we provide a comprehensive array of cutting-edge machines and equipment that consistently yield exceptional outcomes. </p>
							<div style={{ display: "flex", justifyContent: "center", paddingTop: "20px", alignItems: "center", margin: "0 auto" }}>
								<img src="/assets/images/img/spm.jpg" />
							</div>
							<p style={{
								marginTop: "22px",
								fontSize: "15px",
								fontWeight: "bold",
								fontFamily: "'Nexa'!important"
							}}>RAWMART offers compact Special Purpose Machine (SPM) designing capabilities, including:</p>
							<Info info={data["spm"]} />
							<p style={{
								margin: "22px 0px",
								fontSize: "15px",
								textAlign: "center",
								fontWeight: "bold",
								fontFamily: "'Nexa'!important"
							}}>With RAWMART's SPM designing capabilities, clients achieve customized and efficient manufacturing solutions, leading to improved productivity, cost savings, and enhanced product quality.</p>

						</div>

					</div>
				</section>
				<section style={{ paddingTop: "40px", backgroundColor: "rgb(235, 233, 233)" }}>
					<div className="">
						<div className="row">
							<h3
								className="white-color h3Heading heading-h3"
								style={{ marginBottom: "0px" }}
							>
								<span className="clr-blue">Procurement </span>
							</h3>
							<p
								style={{
									margin: "22px 0px",
									fontSize: "20px",
									textAlign: "center",
									fontWeight: "bold",
									fontFamily: "'Nexa'!important"
								}}
							>
								MS , SS from high quality raw material to finish products
							</p>
							<div

							>
								{/* {procurement.map((i) => {
									return <ProductBox key={i} data={`ms-img${i}`} name="Procurement" />;
								})}
								<Info info={data["procurement"]} /> */}
								<ul className="nav nav-tabs" role="tablist" onClick={changeProducts1}>
									<li
										role="presentation"
										className={activeClass1 === "tmtRebears" ? "active" : ""}
									>
										<div className="" id="tmtRebears">
											TMT Bar
										</div>
									</li>
									<li
										role="presentation"
										className={activeClass1 === "mildsteel" ? "active" : ""}
									>
										<div className="" id="mildsteel">
											Mild Steel
										</div>
									</li>
									<li
										role="presentation"
										className={activeClass1 === "stainlesssteel" ? "active" : ""}
									>
										<div className="" id="stainlesssteel">
											Stainless Steel
										</div>
									</li>
									{/* <li
          role="presentation"
          className={activeClass === "polymer" ? "active" : ""}
        >
          <div className="" id="polymer">
            Engineering Polymer
          </div>
        </li> */}
									<li
										role="presentation"
										className={activeClass1 === "otherproduct" ? "active" : ""}
									>
										<div className="" id="otherproduct">
											Other Steel Products
										</div>
									</li>
									<li
										role="presentation"
										className={activeClass1 === "aluminum" ? "active" : ""}
									>
										<div className="" id="aluminum">
											Aluminum
										</div>
									</li>
								</ul>
								<div className="container">
									{/* <div className="tab-content"> */}
									<div
										className={
											activeClass1 === "aluminum"
												? CommonClass + " show active"
												: CommonClass
										}
									>
										{aluminum.map((i) => {
											return <ProductBox key={i} data={`al-img${i}`} name="Aluminum" />;
										})}
										<Info info={data.aluminum} />
									</div>
									<div
										className={
											activeClass1 === "tmtRebears"
												? CommonClass + " show active"
												: CommonClass
										}
									>
										{tmtRebears.map((i) => {
											return <ProductBox key={i} data={`tmt${i}`} name="tmtRebears" />;
										})}
										<Info info={data.tmtRebears} />
									</div>
									<div
										className={
											activeClass1 === "stainlesssteel"
												? "active show " + CommonClass
												: CommonClass
										}
									>
										{stainlesssteel.map((i) => {
											return (
												<ProductBox key={i} data={`ss-img${i}`} name="Stainless Steel" />
											);
										})}
										<Info info={data.stainlesssteel} />
									</div>
									<div
										className={
											activeClass1 === "mildsteel"
												? "active show " + CommonClass
												: CommonClass
										}
									>
										{mildsteel.map((i) => {
											return <ProductBox key={i} data={`ms-img${i}`} name="Mild Steel" />;
										})}
										<Info info={data.mildsteel} />
									</div>

									<div
										className={
											activeClass1 === "polymer"
												? "active show " + CommonClass
												: CommonClass
										}
									>
										{polymer.map((i) => {
											return <ProductBox key={i} data={`pl-img${i}`} name="Polymer" />;
										})}
										<Info info={data.polymer} />
									</div>
									<div
										className={
											activeClass1 === "otherproduct"
												? "active show " + CommonClass
												: CommonClass
										}
									>
										{otherproduct.map((i) => {
											return <ProductBox key={i} data={`ot-img${i}`} name="otherstel" />;
										})}
										<Info info={data.otherstel} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section style={{ paddingTop: "40px" }}>
					<div className="">
						<div className="row">
							<h3
								className="white-color h3Heading heading-h3"
								style={{ marginBottom: "15px" }}
							>
								<span className="clr-blue mb-4">Few Projects Completed Till Date </span>
							</h3>
							{/* <p
								style={{
									margin: "22px 0px",
									fontSize: "20px",
									textAlign: "center",
									fontWeight: "bold",
									fontFamily: "'Nexa'!important"
								}}
							>
								Projects completed till date
							</p> */}
							<div

							>
								{/* {procurement.map((i) => {
									return <ProductBox key={i} data={`ms-img${i}`} name="Procurement" />;
								})}
								<Info info={data["procurement"]} /> */}
								<ul className="nav nav-tabs" role="tablist" onClick={changeProducts2}>
									<li
										role="presentation"
										className={activeClass2 === "pharma_product" ? "active" : ""}
									>
										<div className="" id="pharma_product">
											Pharma Products
										</div>
									</li>
									<li
										role="presentation"
										className={activeClass2 === "gangways_for_indian_railways" ? "active" : ""}
									>
										<div className="" id="gangways_for_indian_railways">
											Gangways for Indian Railways										</div>
									</li>
									<li
										role="presentation"
										className={activeClass2 === "material_handeling_equipments" ? "active" : ""}
									>
										<div className="" id="material_handeling_equipments">
											Material Handeling Equipments										</div>
									</li>

									<li
										role="presentation"
										className={activeClass2 === "precision_manufacturing" ? "active" : ""}
									>
										<div className="" id="precision_manufacturing">
											Precision Manufacturing										</div>
									</li>
									<li role="presentation" className={activeClass2 === "spms" ? "active" : ""}>
										<div className="" id="spms">
											SPMs
											{/* <ul className="dropdown-content">
												<li><a href="#spm1" onClick={() => setActiveClass2("spms1")}>Solution Design to Order</a></li>
												<li><a href="#spm2" onClick={() => setActiveClass2("spms2")}>Engineering Solutions</a></li>
											</ul> */}
										</div>
									</li>
								</ul>
								<div className="container">
									{/* <div className="tab-content"> */}
									<div
										className={
											activeClass2 === "pharma_product"
												? CommonClass + " show active"
												: CommonClass
										}
									>
										{pharma_product.map((i) => {
											return <FabricationBox key={i} data={`pharma${i}`} name="Pharma Product" />;
										})}
									</div>
									<div
										className={
											activeClass2 === "gangways_for_indian_railways"
												? CommonClass + " show active"
												: CommonClass
										}
									>
										{gangways_for_indian_railways.map((i) => {
											return <FabricationBox key={i} data={`railway${i}`} name="Gangways for Indian Railways" />;
										})}
									</div>
									<div
										className={
											activeClass2 === "material_handeling_equipments"
												? "active show " + CommonClass
												: CommonClass
										}
									>
										{material_handeling_equipments.map((i) => {
											return (
												<FabricationBox key={i} data={`material${i}`} name="Material Handeling Equipments" />
											);
										})}
									</div>
									<div
										className={
											activeClass2 === "precision_manufacturing"
												? "active show " + CommonClass
												: CommonClass
										}
									>
										{precision_manufacturing.map((i) => {
											return <FabricationBox key={i} data={`precision${i}`} name="Precision Manufacturing" />;
										})}
									</div>
									<div className="row">
										<div
											className={
												activeClass2 === "spms"
													? "active show " + CommonClass + " col-md-6"
													: CommonClass
											}
											id="spm1">
											<h1 style={{ textAlign: "center", marginTop: "45px" }}>Solution Design to Order  </h1>
											{spms1.map((i) => {
												return <FabricationBoxSPMs key={i} data={`solution_design${i}`} name="Precision Manufacturing" />;
											})}
										</div>
										<div
											className={
												activeClass2 === "spms"
													? "active show " + CommonClass + " col-md-6"
													: CommonClass
											}
										>
											<h1 style={{ textAlign: "center", marginTop: "45px" }}>Engineering Solutions </h1>
											{spms2.map((i) => {
												return <FabricationBoxSPMs key={i} data={`engineering_solu${i}`} name="Precision Manufacturing" />;
											})}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section style={{ paddingTop: "40px", backgroundColor: "rgb(235, 233, 233)" }}>
					<div className="container">
						<div className="row" style={{ paddingBottom: "20px" }}>
							<h3
								className="white-color h3Heading heading-h3"
								style={{ marginBottom: "0px" }}
							>
								Engineering<span className="clr-blue"> Solution</span>
							</h3>
							<p
								style={{
									margin: "22px 0px",
									fontSize: "20px",
									textAlign: "center",
									fontWeight: "bold",
									fontFamily: "'Nexa'!important"
								}}
							>
								Design to Order — Complete End-to-End Solutions
							</p>
							<div

							>
								<div style={{ display: "flex", justifyContent: "center", paddingTop: "20px", alignItems: "center", margin: "0 auto" }}>
									<img src="/assets/images/img/engineering_solutions.jpg" style={{ width: "100%", height: "100%" }} />
								</div>
								<p style={{
									margin: "22px 0px",
									fontSize: "20px",
									textAlign: "center",
									fontWeight: "bold",
									fontFamily: "'Nexa'!important"
								}}>Rawmart: Quality-Driven Engineering Solutions</p>
								{/* <Info info={data["engineering_solution"]} /> */}
								<div className="col-md-12 row flexWrap pb-5">
									{engineering_data.map((i, j) => {
										return (
											<EngineeringBox engineering_data={i} key={j} first={j === 0 ? true : false} />
										);
									})}
								</div>
								{/* <Info info={data.processes} /> */}
							</div>
						</div>
					</div>
				</section>
				<section className="design" style={{ paddingTop: "40px" }}>
					<div className="container">
						<div className="row" style={{ paddingBottom: "20px" }}>
							<h3
								className="white-color h3Heading heading-h3"
								style={{ marginBottom: "0px" }}
							>
								<span className="clr-blue">Design</span>
							</h3>
							<p
								style={{
									margin: "22px 0px",
									fontSize: "20px",
									textAlign: "center",
									fontWeight: "bold",
									fontFamily: "'Nexa'!important"
								}}
							>
								Rawmart tech ecosystem, along with the efficient role playing in each step to address the problems of SME's:
							</p>
							<div>
								<div style={{ display: "flex", justifyContent: "center", paddingTop: "20px", alignItems: "center", margin: "0 auto" }}>
									<img src="/assets/images/img/design.jpg" style={{ width: "100%", height: "80%" }} />
								</div>
								{/* <Info info={data["design_capabilities"]} /> */}
								<div className="col-md-12 row flexWrap">
									{design_data.map((i, j) => {
										return (
											<DesignBox design_data={i} key={j} first={j === 0 ? true : false} />
										);
									})}
								</div>
								{/* <Info info={data.processes} /> */}
							</div>
						</div>
					</div>
				</section>
				<section className="cutmClass bg-img-contactus">
					<div className="container">
						<ContactForm
							title="Get a Quote"
							subTitle="Fill up the form and our team will get back to you within 24 hours"
							productSection={true}
						/>
					</div>
				</section>

				{/* <div className="boxes-column" style={{ margin: 0 }}>
				<ul>
					<li style={{ width: "25%" }}>

						<div
							className="boxes-desc"
							style={{
								fontFamily: "cursive",
								fontFamily: "Nexa",
								color: "gray",
								fontSize: "1rem",
							}}
						>
							<h4>Contact Person</h4>
							<p style={{ fontSize: "16px" }}>Vikrant Pardeshi</p>
						</div>
					</li>

					<li style={{ width: "25%" }}>

						<div className="boxes-desc">
							<h4>Designation</h4>
							<p style={{ fontSize: "16px", color: "#fff" }}>Sales Executive</p>
						</div>
					</li>

					<li style={{ width: "25%" }}>

						<div className="boxes-desc">
							<h4>Phone No.</h4>
							<a
								href="tel:+919309934491"
								target="_blank"
								style={{ color: "#fff" }}
							>
								<p style={{ fontSize: "16px" }}> +91 9309934491</p>
							</a>
						</div>
					</li>
					<li style={{ width: "25%" }}>

						<div className="boxes-desc">
							<h4>Email</h4>
							<a
								href="mailto:vikrant.Rawmart@gmail.com"
								target="_blank"
								style={{ color: "#fff" }}
							>
								<p style={{ fontSize: "16px" }}>vikrant.rawmart@gmail.com</p>
							</a>
						</div>
					</li>
				</ul>
			</div> */}
			</section>
		</>
	);
}