export default function info({ info3 }) {
	return (
		<div className="col-md-12 right-column">
			<div className="service-right-desc">
				{info3.last ? (
					<OtherProduct info3={info3} />
				) : (
					<DefaultComponent info3={info3} />
				)}
			</div>
		</div>	

		// <div className="row">
		// 	<div className="col-md-6 col-sm-12 ">
		// 		{
		// 			info3.map((obj)=>{
		// 				return <><h3>{obj.productName}</h3><div>{obj.para}</div></>;
		// 			})
		// 		}
		// 	</div>
		// </div>
	);
}

export const DefaultComponent = ({ info3 }) => {
	return (
		<div className="para">
			<CommonDiv info3={info3} />

			{info3.headingTitle2 ? (
				<p className="boldPara">{info3.headingTitle2}</p>
			) : null}
			{info3.listOfProduces2 ? (
				<ul>
					{info3.listOfProduces2
						? info3.listOfProduces2.map((i, j) => {
								return <li info3={j}>{i}</li>;
						  })
						: null}
				</ul>
			) : null}

			{info3.headingTitle3 ? (
				<p className="boldPara">{info3.headingTitle3}</p>
			) : null}
			{info3.listOfProduces3 ? (
				<ul>
					{info3.listOfProduces3
						? info3.listOfProduces3.map((i, j) => {
								return <li info3={j}>{i}</li>;
						  })
						: null}
				</ul>
			) : null}

			{info3.headingTitle4 ? (
				<p className="boldPara">{info3.headingTitle4}</p>
			) : null}
			{info3.listOfProduces4 ? (
				<ul>
					{info3.listOfProduces4
						? info3.listOfProduces4.map((i, j) => {
								return <li info3={j}>{i}</li>;
						  })
						: null}
				</ul>
			) : null}
		</div>
	);
};

export const OtherProduct = ({ info3 }) => {
    return (
      <div>
        {info3.products.map((i, j) => {
          return <CommonDiv key={j} info3={i} srNo={j + 1} />;
        })}
      </div>
    );
  };
  
  export const CommonDiv = ({ info3, srNo }) => {
    return (
      <div className="para">
        {info3.productName ? (
          <p className="boldPara">
            {srNo}. {info3.productName}
          </p>
        ) : null}
        {info3.para.map((i, j) => {
          return <li key={j}>{i}</li>;
        })}
  
        {info3.headingTitle1 ? <p>{info3.headingTitle1}</p> : null}
        <ul>
          {info3.listOfProduces
            ? info3.listOfProduces.map((i, j) => {
                return <li key={j}>{i}</li>;
              })
            : null}
        </ul>
        {info3.list ? (
          <ul>
            {info3.listItem.map((i, j) => {
              return <li key={j}>{i}</li>;
            })}
          </ul>
        ) : null}
      </div>
    );
  };
  

