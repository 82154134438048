import FeatureSections from "./featureSection";
import { useState } from "react";

export default function Aboutus() {
	const [showMore, setShowMore] = useState(false);
	const changeLoadMore = () => {
		setShowMore(!showMore);
	};
	return (<>
		{/* <Helmet>
        <title>New Page Title</title>
      </Helmet> */}
		<section className="pad100-top-bottom" id="aboutus">
			<div className="container">
				<FeatureSections></FeatureSections>
				<h3 className="heading-h3">
					Unleashing the unseen power of  <span className="clr-blue">SME’s</span>
				</h3>
				<div className="row" style={{}}>
					<div
						className="col-lg-6 col-md-6 col-sm-12 col-xs-12 img"
						style={{ marginTop: "35px",marginBottom:"20px" }}
					>
						<span className="">
							<img
								src="assets/images/aboutus/img5.png"
								className="img-responsive res-img"
								alt="about"
								style={{
									marginLeft: 0,
									height: "400px",
									objectFit: "cover",
									width:"100%",
									// marginTop: "10px",
								}}
							/>
						</span>
					</div>
					<div className="col-lg-6 col-m-6 col-s-12 col-x-6 martop30 responsiv-div aboutRawMart">
						<p className="fnt-14">
						Rawmart is an emerging pioneer in India for the brilliantly supply of industrial and infrastructural procurement products. Supported by innovation and upheld by a group of qualified specialists, our endeavor is to bring the finest of essential and secondary raw materials right from their prime sources at delightfully reasonable prices.
						</p>
						<p className="fnt-14">
						Clients work with us in lots of different ways and our roles are varied for each one. From procurement consulting for industrial and infrastructure businesses, to providing strategic advice for SMEs, or even directly tackling a problem head on.
						</p>
						

						{/* <p className="fnt-14">
              Within the competitive business world, various potential ventures
              and services are put out to tender. In order to have a total
              understanding of Tender we ought to dive more profoundly. In
              today’s competitive and volatile economy taking part in Government
              tenders can’t be an secondary alternative or business can’t afford
              to neglect the colossal public procurement market, which is
              essentially recession free.
            </p> */}
						{/* <p className="fnt-14"> */}

						{/* {showMore ? ( */}
						{/* <div> */}
						{/* 
              While fulfilling government tenders’ paperwork, most individuals,
              particularly a tenderer, tend to make several mistakes. Thus,
              taking professional help in this perspective is much required. In
              this perspective, Rawmart can help you out. Our professionals have
              been working in this specific field for many years and, thus, can
              aid you with anything and everything.
            </p>
            <p className="fnt-14">
              Be it process integration or product procurement, our
              sophisticated tech-backed interface makes every phase of purchase
              and delivery easy, error-free, quick, and reasonable for our
              clients.
            </p>
            <p className="fnt-14">
              Rawmart’s contribution to reviving secondary makers of raw
              materials has been widely appreciated industry-wide, as well.
            </p> */}
						{/* </div> */}
						{/* ) : (
              ``
            )}
             */}
						{/* <div className="showmorebutton">
              <p
                style={{ border: "1px solid #ccc",  padding:"15px" }}
                className="header-requestbtn contactus-btn hvr-bounce-to-right"
                onClick={changeLoadMore}
              >
                {!showMore ? `Load More` : `Load Less`}
              </p>
            </div> */}
						{/* <Link
              data-animation="animated fadeInUp"
              className="header-requestbtn more-infobtn hvr-bounce-to-right"
              to="/"
              style={{ margin: "auto" }}
            >
              more info
            </Link> */}
					</div>
				</div>
			</div>
		</section>
		</>
	);
}
