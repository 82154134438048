export default function methodologies_sublist({ info5 }) {
	return (
		<div className="col-md-12 right-column">
			<div className="service-right-desc">
				{info5.last ? (
					<OtherProduct info5={info5} />
				) : (
					<DefaultComponent info5={info5} />
				)}
			</div>
		</div>	

		// <div className="row">
		// 	<div className="col-md-6 col-sm-12 ">
		// 		{
		// 			info5.map((obj)=>{
		// 				return <><h3>{obj.productName}</h3><div>{obj.para}</div></>;
		// 			})
		// 		}
		// 	</div>
		// </div>
	);
}

export const DefaultComponent = ({ info5 }) => {
	return (
		<div className="para">
			<CommonDiv info5={info5} />

			{info5.headingTitle2 ? (
				<p className="boldPara">{info5.headingTitle2}</p>
			) : null}
			{info5.listOfProduces2 ? (
				<ul>
					{info5.listOfProduces2
						? info5.listOfProduces2.map((i, j) => {
								return <li key={j}>{i}</li>;
						  })
						: null}
				</ul>
			) : null}

			{info5.headingTitle3 ? (
				<p className="boldPara">{info5.headingTitle3}</p>
			) : null}
			{info5.listOfProduces3 ? (
				<ul>
					{info5.listOfProduces3
						? info5.listOfProduces3.map((i, j) => {
								return <li key={j}>{i}</li>;
						  })
						: null}
				</ul>
			) : null}

			{info5.headingTitle4 ? (
				<p className="boldPara">{info5.headingTitle4}</p>
			) : null}
			{info5.listOfProduces4 ? (
				<ul>
					{info5.listOfProduces4
						? info5.listOfProduces4.map((i, j) => {
								return <li key={j}>{i}</li>;
						  })
						: null}
				</ul>
			) : null}
		</div>
	);
};

export const OtherProduct = ({ info5 }) => {
	return (
		<div>
			{info5.products.map((i, j) => {
				return <CommonDiv key={j} info5={i} srNo={j + 1} />;
			})}
		</div>
	);
};


export const CommonDiv = ({ info5, srNo }) => {
    return (
      <div className="para">
        {info5.productName ? (
          <p className="boldPara">
            {srNo}. {info5.productName}
          </p>
        ) : null}
        {Array.isArray(info5.para) ? ( // Check if info5.para is an array
          <ul>
            {info5.para.map((i, j) => {
              return <li key={j}>{i}</li>;
            })}
          </ul>
        ) : (
          <li>{info5.para}</li> // Render a single paragraph if info5.para is not an array
        )}
  
        {info5.headingTitle1 ? <p>{info5.headingTitle1}</p> : null}
        <ul>
          {info5.listOfProduces
            ? info5.listOfProduces.map((i, j) => {
                return <li key={j}>{i}</li>;
              })
            : null}
        </ul>
        {info5.list ? (
          <ul>
            {info5.listItem.map((i, j) => {
              return <li key={j}>{i}</li>;
            })}
          </ul>
        ) : null}
      </div>
    );
  };
  


