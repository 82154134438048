export default function statisticSection(){
  return (
    <section className="home2 home4 bestthing-section text-center">
      <div className="container">
        <div className="bestthing-text-column home4-bestthing-txt">
          <h2 className="heading-h3">
            THE BEST THING IN <br />
            <span>RAWMART</span>
          </h2>
        </div>
        <div className="static-section home3-static home4-static-section sectionHighlight">
          <ul className="listItemThings">
            <li>
              <img src="assets/images/keyFeatures/img1.png" alt="icons" />
              <p>Not just Raw Materials</p>
            </li>
            <li>
              <img src="assets/images/keyFeatures/img2.png" alt="icons" />
              <p>Delivering beyond expectations</p>
            </li>
            <li>
              <img src="assets/images/keyFeatures/img3.png" alt="icons" />
              <p>We embrace technology</p>
            </li>
            <li>
              <img src="assets/images/keyFeatures/img4.png" alt="icons" />
              <p style={{ width: "100%" }}>
                Supplying materials and building trust, supplying materials and
                building businesses
              </p>
            </li>
            <li>
              <img src="assets/images/keyFeatures/img5.png" alt="icons" />
              <p>Stronger business stronger nation</p>
            </li>
            <li>
              <img src="assets/images/keyFeatures/img6.png" alt="icons" />
              <p>We are detail oriented</p>
            </li>
          </ul>
        </div>
        <div className="static-section home3-static home4-static-section">
          <ul>
            <li>
              <h2>150+</h2>
              <p>Clients</p>
            </li>
            <li>
              <h2>1700+</h2>
              <p>deliveries made</p>
            </li>
            <li>
              <h2>300+</h2>
              <p>vendor and logistics partners</p>
            </li>
            <li>
              <h2>200+</h2>
              <p> Total Project Running</p>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}