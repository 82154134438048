export default function info({ info }) {
	return (
		<div className="col-md-12 right-column">
			<div className="service-right-desc">
				{info.last ? (
					<OtherProduct info={info} />
				) : (
					<DefaultComponent info={info} />
				)}
			</div>
		</div>	

		// <div className="row">
		// 	<div className="col-md-6 col-sm-12 ">
		// 		{
		// 			info.map((obj)=>{
		// 				return <><h3>{obj.productName}</h3><div>{obj.para}</div></>;
		// 			})
		// 		}
		// 	</div>
		// </div>
	);
}

export const DefaultComponent = ({ info }) => {
	return (
		<div className="para">
			<CommonDiv info={info} />

			{info.headingTitle2 ? (
				<p className="boldPara">{info.headingTitle2}</p>
			) : null}
			{info.listOfProduces2 ? (
				<ul>
					{info.listOfProduces2
						? info.listOfProduces2.map((i, j) => {
								return <li key={j}>{i}</li>;
						  })
						: null}
				</ul>
			) : null}

			{info.headingTitle3 ? (
				<p className="boldPara">{info.headingTitle3}</p>
			) : null}
			{info.listOfProduces3 ? (
				<ul>
					{info.listOfProduces3
						? info.listOfProduces3.map((i, j) => {
								return <li key={j}>{i}</li>;
						  })
						: null}
				</ul>
			) : null}

			{info.headingTitle4 ? (
				<p className="boldPara">{info.headingTitle4}</p>
			) : null}
			{info.listOfProduces4 ? (
				<ul>
					{info.listOfProduces4
						? info.listOfProduces4.map((i, j) => {
								return <li key={j}>{i}</li>;
						  })
						: null}
				</ul>
			) : null}
		</div>
	);
};

export const OtherProduct = ({ info }) => {
	return (
	  <div>
		{info.products.map((i, j) => {
		  return <CommonDiv key={j} info={i} srNo={j + 1} />;
		})}
	  </div>
	);
  };
  
  export const CommonDiv = ({ info, srNo }) => {
	return (
	  <div className="para">
		{info.productName ? (
		  <p className="boldPara">
			{srNo}. {info.productName}
		  </p>
		) : null}
		{info.para.map((i, j) => {
		  return <li key={j}>{i}</li>;
		})}
  
		{info.headingTitle1 ? <p>{info.headingTitle1}</p> : null}
		<ul>
		  {info.listOfProduces
			? info.listOfProduces.map((i, j) => {
				return <li key={j}>{i}</li>;
			  })
			: null}
		</ul>
		{info.list ? (
		  <ul>
			{info.listItem.map((i, j) => {
			  return <li key={j}>{i}</li>;
			})}
		  </ul>
		) : null}
	  </div>
	);
  };
  


