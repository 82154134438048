import React, {  useEffect } from "react";
import { Link } from "react-router-dom";

function footer({ setSubFooter }) {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	useEffect(() => {}, [setSubFooter]);

	const navigateToAboutUS = () => {
		setTimeout(() => {
			window.scrollTo(0, 600);
		}, 300);
	};
	// const scrollToTop = () => {
	//     window.scrollTo({
	//       top: 0,
	//       behavior: "smooth",
	//       /* you can also use 'auto' behaviour
	//        in place of 'smooth' */
	//     });
	//   };
	return (
		<footer>
			{/* {hideSubFooter ? ( */}
			{!setSubFooter ? (
				<div className="yellow-background solution-available text-center">
					<div className="container">
						<h5>
							To know more about <span>Our Services</span>
						</h5>
						<a
							data-animation="animated fadeInUp"
							className="header-requestbtn contactus-btn hvr-bounce-to-right"
							href="/contactus"
						>
							Contact us
						</a>
					</div>
				</div>
			) : null}

			{/* ) : null} */}
			<div className="ftr-section">
				<div className="container">
					<div className="row">
						<div
							className="col-md-4 col-sm-6 col-xs-12"
							style={{ flexDirection: "column" }}
						>
							<div className="">
								<h6>ABOUT RAWMART</h6>
								<p className="marbtm20 line-height26">
									Rawmart is an emerging leader in India for intelligent supply
									of industrial and infrastructural raw material products...
								</p>
								<Link
									className="ftr-read-more res-btn"
									to="/"
									onClick={navigateToAboutUS}
								>
									Read More
								</Link>
							</div>
						</div>
						<div className="col-md-3 col-sm-6 col-xs-12" style={{marginBottom:"20px"}}>
							<h6>INFORMATION</h6>
							<ul className="footer-link">
								<li className="dropdown ">
									<Link to="/" className="">
									SME Ecosystem
									</Link>
								</li>
								<li className="dropdown">
									<Link to="/Invovle">Involvement</Link>
								</li>
								<li className="dropdown">
									<Link to="/Solve">Solutions</Link>
								</li>

								<li className="dropdown">
									<Link to="/Evolve">Evolvement</Link>
								</li>
								<li className="dropdown">
									<Link to="/contactus">Contact Us</Link>
								</li>
								<li className="dropdown">
									<Link to="/privacypolicy">Privacy Policy</Link>
								</li>
							</ul>
						</div>
						<div className="col-md-4 col-sm-6 col-xs-12 ftr-link-column">
							<h6 >
								CONTACT US
							</h6>
							<div className="footer-socials">
								<ul className="footer-link contactUsFooter">
									<li className="address">
										<a
											className="ancher"
											target="__blank"
											href="https://goo.gl/maps/a2zU5SMYdbCDj8ac7"
										>
											<i className="fa-solid fa-location-dot"></i>
											<span>
												6/50,Third Floor,Parsanees Colony Maharshi Nagar, Pune
												Maharashtra,India - 411037
											</span>
										</a>
									</li>
									
									<li className="address flex">
										<i className="fa-solid fa-phone"></i>
										<div>
											<a className="ancher" href="tel:+918308006002">
												<span>+91 8308006002</span>
											</a>

											<a className="ancher" href="tel:+917009440394">
												<span>
													+91 7009440394 <br />
												</span>
											</a>
										</div>
									</li>
									<li className="address flex">
										<i className="fa-solid fa-envelope"></i>
										<div>
											<a
												className="ancher"
												target="_blank"
												href="mailto:sr@rawmart.in"
											>
												<span>sr@rawmart.in</span>
											</a>

											<a
												className="ancher"
												target="_blank"
												href="mailto:tac@rawmart.in"
											>
												<span>tac@rawmart.in</span>
											</a>
											<a
												className="ancher"
												target="_blank"
												href="mailto:shrenik@rawmart.in"
											>
												<span>shrenik@rawmart.in</span>
											</a>
										</div>
									</li>
									<li className="address">
										<a className="ancher" href="mailto">
											<span>
												<br />
											</span>
										</a>
									</li>
								</ul>

								<div className="header-socials footer-socials centerData-sp">
									<a
										href="https://www.instagram.com/rawmart_material_solutions/"
										target="_blank"
									>
										<i className="fa-brands fa-instagram"></i>
									</a>
									<a href="#">
										<i className="fa-brands fa-twitter"></i>
									</a>
									
									<a
										href="https://www.linkedin.com/company/rawmart/"
										target="_blank"
									>
										<i className="fa-brands fa-linkedin-in"></i>
									</a>
								</div>
							</div>
						</div>
					</div>
					<div className="footer-btm">
						<div className="col-md-6 col-sm-6 col-xs-12 pad-left_zero pad-right_zero">
							<p>
								Copyright © {new Date().getFullYear()}{" "}
								<Link to="/">Rawmart</Link>. All Rights Reserved.
							</p>
						</div>
						<div className="col-md-4 col-sm-6 col-xs-12 pad-left_zero pad-right_zero pull-right">
							<p className="text-right">
								Maintained by:{" "}
								<a href="https://accucia.com" target="_blank">
									Accucia Softwares Pvt. Ltd.
								</a>
							</p>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
}

export default footer;
