import React, { useState } from "react";
import ContactForm from '../common/contacrForm';

export default function Contactus() {
 
  return (
    <section className="cutmClass bg-img-contactus">
      <div className="container">
        <ContactForm />        
      </div>
    </section>
  );
}
