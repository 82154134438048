import React, { useState, useRef } from "react";
import validator from "validator";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function ContactForm({
	title = "Enquiry Form",
	subTitle = "Do drop in any of your queries or suggestions to the below email id.",
	productSection = false,
}) {
	let [data, setData] = useState({
		name: "",
		email: "",
		number: "",
		enquiryFor: 0,
		cityname: "",
		company_name: "",
	});

	const form = useRef();
	const submitBtn = useRef();

	const [errors, setErrors] = useState({});

	const onChange = (e) => {
		setData({ ...data, [e.target.name]: e.target.value });

		// Clear the error message for the corresponding input
		setErrors({ ...errors, [e.target.name]: "" });
	};

	const sendEmail = (e) => {
		e.preventDefault();

		const formErrors = {};

		if (validator.isEmpty(data.name)) {
			formErrors.name = "Please Enter Your Full Name";
		}
		if (!data.email.trim()) {
			formErrors.email = "Please Enter Your Email Address";
		} else if (!data.email.includes('@')) {
			formErrors.email = "Invalid email address";
		}

		if (!validator.isMobilePhone(data.number)) {
			formErrors.number = "Please Enter Your Contact Number";
		} else if (!validator.isLength(data.number, { min: 10, max: 10 })) {
			formErrors.number = "Contact number should be 10 digits";
		}
		if (validator.isEmpty(data.company_name)) {
			formErrors.company_name = "Please Enter Your Company Name";
		}

		// Show errors for the current field being edited
		setErrors(formErrors);

		// Check if any errors exist
		if (Object.keys(formErrors).length > 0) {
			return;
		}

		submitBtn.current.setAttribute("disabled", "disabled");

		const template_id = productSection ? "template_u2el30r" : "template_3yma1x7";
		emailjs
			.sendForm(
				"service_ecy171g",
				template_id,
				form.current,
				"jQtBqhVwn2IiQpIsH"
			)
			.then(
				(result) => {
					console.log(result.text);
					submitBtn.current.removeAttribute("disabled");
					form.current.reset();

				},
				(error) => {
					console.log(error.text);
				}
			);

		// Show pop-up
		toast.success("User Details Submited !", {
			theme: "colored",
		});
		setData({
			name: "",
			email: "",
			number: "",
			enquiryFor: 0,
			company_name: "",
		});
		e.target.reset();
	};


	return (
		<div className="row text-center" style={{ marginBottom: "30px" }}>
			<ToastContainer />
			<h3 className="mar-btm30 heading-h3">{title}</h3>
			<p className="fnt-18">{subTitle}</p>
			<div className="contact-form">
				<form ref={form}>
					<div className="col-md-6 form-field input-halfrght">
						<input
							name="name"
							type="text"
							className="form-input"
							placeholder="Full Name*"
							value={data.name}
							onChange={onChange}
						/>
						{errors.name && (
							<span className="error-message">{errors.name}</span>
						)}
					</div>
					<div className="col-md-6 form-field input-halflft">
						<input
							name="email"
							type="email"
							className="form-input"
							placeholder="Email*"
							value={data.email}
							onChange={onChange}
						/>
						{errors.email && (
							<span className="error-message">{errors.email}</span>
						)}
					</div>
					<div className="col-lg-12 col-md-12 form-field">
						<input
							name="number"
							type="tel"
							className="form-input"
							placeholder="Contact Number*"
							value={data.number}
							onChange={(e) => {
								const sanitizedValue = e.target.value.replace(/[^\d]/g, ''); // Remove non-digit characters
								onChange({ target: { name: 'number', value: sanitizedValue } });
							  }}
							maxLength={10}
						/>
						{errors.number && (
							<span className="error-message">{errors.number}</span>
						)}
					</div>

					<div className="col-lg-12 col-md-12 form-field">
						<input
							name="company_name"
							type="text"
							className="form-input"
							placeholder="Company Name*"
							onChange={onChange}
						/>
						{errors.company_name && (
							<span className="error-message">{errors.company_name}</span>
						)}
					</div>

					{productSection ? (
						<React.Fragment>
							<div className="col-lg-12 col-md-12 form-field">
								<input
									name="product_line"
									type="text"
									className="form-input"
									placeholder="Product Line"
								/>
							</div>
							<div className="col-lg-12 col-md-12 form-field">
								<textarea
									name="message"
									type="text"
									className="form-input"
									placeholder="Enter Message"
								></textarea>
							</div>
						</React.Fragment>
					) : (
						<React.Fragment>
							<div className="col-lg-12 col-md-12 form-field">
								<select name="enquiry_for" className="form-input">
									<option value="0">Enquiry For</option>
									<option value="Consultation_Services">
										Consultation Services
									</option>
									<option value="Raw_Material_Enquiry">
										Raw Material Enquiry
									</option>
								</select>
							</div>
							<div className="col-lg-12 col-md-12 form-field">
								<input
									name="cityname"
									type="text"
									className="form-input"
									placeholder="City Name"
								/>
								{errors.cityname && (
									<span className="error-message">{errors.cityname}</span>
								)}
							</div>
						</React.Fragment>
					)}

					<div className="col-md-12 form-field no-margin">
						<button
							ref={submitBtn}
							type="button"
							className="form-submit-btn submitButton"
							onClick={sendEmail}
						>
							Submit Now
						</button>
					</div>
				</form>
			</div>
		</div>
	);
}
