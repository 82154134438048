import { WhyRawmartBox } from './whyRawmartBox';

export default function WhyRawmart() {
  let data = [
    {
      title: "Procurement Powerhouse",
      des: `Rawmart's, as a specialized platform for raw material procurement, understands the challenges faced by SME's in sourcing quality materials at competitive prices. By establishing strategic partnerships with SME's, rawmart's can leverage their network and industry expertise to offer tailored solutions to meet the unique requirements of SME's Rawmart is your one-stop destination for all your procurement needs. We bring together a vast array of raw materials, carefully curated from trusted suppliers and manufacturers, giving you access to an extensive catalog that meets your every requirement. By partnering with us, you tap into a procurement powerhouse that ensures a seamless and reliable supply chain, allowing you to focus on what you do best - manufacturing excellence.`,
      img: "assets/images/why_rawmart/procurement_powerhouse.png",
    },

    {
      title: "Engineering Solutions",
      des: `At Rawmart, we don't just stop at procurement; we go above and beyond to provide cutting-edge engineering solutions that drive your manufacturing capabilities to new heights. Our team of experts understands the intricacies of various industries and offers customized solutions tailored to your specific needs. From sourcing rare materials to providing specialized packaging and supply chain optimization, we empower you to streamline your operations, enhance efficiency, and achieve manufacturing excellence.`,
      img: "assets/images/why_rawmart/engineering_solutions.png",
      // list: [
      //   "locate required raw materials",
      //   "book and pay through a single window interface",
      //   "access elaborate online and offline support",
      //   "discover profit and growth opportunities",
      // ],
    },

    {
      title: "Technology-driven Approach",
      des: ` Embracing the latest technological advancements, Rawmart brings you a state-of-the-art platform that revolutionizes the way you procure and manufacture. Our advanced online portal provides you with a seamless user experience, offering real-time inventory tracking, intuitive interfaces, and simplified order placement. With our tech-drivenapproach, we ensure that you stay ahead of the curve, leveraging innovation to drive your growth and competitiveness.`,
      // list: [
      //   "Quick order processing",
      //   "Timely deliveries",
      //   "Answering your queries",
      // ],
      img: "assets/images/why_rawmart/technology_driven.png",
      des2: "So that you can meet your production commitments and maintain smooth and hassle-free supply.",
    },

    {
      title: "Unparalleled Support",
      des: `Rawmart goes beyond being a supplier; we become your trusted partner in success. Our team of industry experts and engineers is dedicated to supporting you every step of the way. We offer value-added services, including expert guidance, technical support, and industry insights, empowering you to make informed decisions and optimize your manufacturing processes. With Rawmart by your side, you gain a competitive edge and the confidence to pursue exponential growth.`,
      // des2: `Some of the domains we extensively cover include sales,
      //             marketing, supply chain, finance, human resource, IT. research
      //             & business analysis.`,
      img: "assets/images/why_rawmart/unparalleled.png",
    },
    {
      title: "Collaborative Ecosystem",
      des: `Rawmart fosters a collaborative ecosystem that connects like-minded companies, facilitating networking, collaboration, and partnership opportunities. Our industry events, interactive forums, and knowledge-sharing sessions create an environment where you can forge alliances, gain insights from industry leaders, and explore new avenues for growth. By joining our ecosystem, you become part of a vibrant community that propels your business towards exponential success.`,
      img: "assets/images/why_rawmart/collaborative_ecosystem.png",
    },
    {
      title: "Exponential Growth",
      des: `When you partner with Rawmart, you unlock the potential for exponential growth. Our unwavering commitment to providing unparalleled access to raw materials, customized engineering solutions, and comprehensive support empowers your company to expand production capabilities, enter new markets, and fulfill your aspirations. With Rawmart as your growth partner, your success knows no bounds.`,
      img: "assets/images/why_rawmart/exponential_growth.png",
    },
  ];
  return (
    <section className="home4-service-section bgSepration">
      <div className="container">
        <div className="row ">
          <div className="head-section wdt-100 mb-55 centerData">
            <div className="col-lg-5 col-md-6 col-sm-4 col-xs-12">
              <h3 className="heading-h3 noMargin">
                Why<span className="clr-blue"> Rawmart??</span>
              </h3>
            </div>
            <div className="col-lg-7 col-md-6 col-sm-12 col-xs-12">
              <p className="fnt-14" style={{wordSpacing:"-1px"}}>
              <span className='clr-black'>Rawmart: </span>
              Unleashing Exponential Growth for Manufacturing and Procurement Solutions.
              At Rawmart, we revolutionize the manufacturing and procurement landscape, empowering companies to unlock exponential growth and soar to new heights. With our unwavering commitment to providing comprehensive engineering solutions and unparalleled access to raw materials, we become the catalyst that propels your business towards exponential success.
              </p>
            </div>
          </div>
          <div className="col-md-12 row flexWrap">
            {data.map((i, j) => {
              return (
                <WhyRawmartBox data={i} key={j} first={j === 0 ? true : false} />
              );
            })}
          </div>
          <div className='col-md-12'style={{backgroundColor:"#f2f2f2",borderRadius:"15px",boxShadow: "rgb(170, 170, 170) 2px 0px 6px",marginTop:"30px"}}>
          <p className="fnt-14"
          style={{textAlign:"center",
          fontWeight:"bold",padding:"15px"}}>Join Rawmart today and witness your company's exponential growth like never before. Together, we will transform the way you procure, manufacture, and conquer new horizons.</p>
          </div>
        </div>
      </div>
    </section>
  );
}