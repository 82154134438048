import OurProcess from "../home/ourProcess";
import { useEffect } from "react";
export default function TMAC() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<section className="home4 recent-project-section pad100-top projectsec1">
			<div className="container">
				<h3
					className="white-color h3Heading heading-h3"
					style={{ marginBottom: "0px" }}
				>
					Tender Management
					<span className="clr-blue"> and Consulting</span>
				</h3>
				<p
					style={{
						marginBottom: "20px",
						fontSize: "22px",
						textAlign: "center",
						marginTop: "10px",
					}}
				>
					Get professional Advisory from ou tender consultants
				</p>

				<div className="tender__management__img" style={tenderManagement}>
					<img
						src="\assets\images\banners\consulting pic 4.png"
						alt="tender__management__img"
						style={{ objectFit: "contain", height: "100%", width: "100%" }}
					/>
				</div>
				<p
					style={{
						fontSize: "16px",
						marginBottom: "20px",
						textAlign: "justify",
					}}
				>
					Within the competitive business world, various potential ventures and
					services are put out to tender. In order to have a total understanding
					of Tender we ought to dive more profoundly. In today’s competitive and
					volatile economy taking part in Government tenders can’t be an
					secondary alternative or business can’t afford to neglect the colossal
					public procurement market, which is essentially recession free.
				</p>
				<p
					style={{
						fontSize: "16px",
						marginBottom: "20px",
						textAlign: "justify",
					}}
				>
					While fulfilling government tenders’ paperwork, most individuals,
					particularly a tenderer, tend to make several mistakes. Thus, taking
					professional help in this perspective is much required. In this
					perspective, Rawmart can help you out. Our professionals have been
					working in this specific field for many years and, thus, can aid you
					with anything and everything.
				</p>
				<p
					style={{
						marginBottom: "20px",
						fontSize: "22px",
						textAlign: "center",
					}}
				>
					Tender Access and Management Portal
				</p>
				<p
					style={{
						fontSize: "16px",
						marginBottom: "20px",
						textAlign: "justify",
					}}
				>
					Rawmart is a specialist within the space of Tender Management and
					counselling with the point to prepare manufacturers & construction
					companies for PSU prerequisites documents impeccably. Our professional
					offer assistance and advice will significantly increase your chances
					of winning tenders and bids.{" "}
				</p>
				<p
					style={{
						fontSize: "16px",
						marginBottom: "20px",
						textAlign: "justify",
					}}
				>
					No matter on the off chance that your organisation is brand new to
					tendering or requires lots of hands-on back, or you are a prepared
					bidder and need advice on picking up a couple of more points to
					improve your hit rate. Our tender experts will make sure you get a
					profoundly attentive, personal service to help with tendering success.
				</p>
				<p
					style={{
						fontSize: "16px",
						marginBottom: "20px",
						textAlign: "justify",
					}}
				>
					We work with 42 out of 348 PSU companies in India. From Indian
					Railways, BEL and BHEL to HPCL, HAL, NTPC and more, our list of
					companies is developing consistently.
				</p>
				<p
					style={{
						fontSize: "16px",
						marginBottom: "20px",
						textAlign: "justify",
					}}
				>
					We have been a strategic partner of various MNCs for supporting them
					with our services.{" "}
				</p>
				<p
					style={{
						fontSize: "16px",
						marginBottom: "20px",
						textAlign: "justify",
					}}
				>
					The Government tender is most preferred since it offers a good quantum
					of work in conjunction with timely payment.
				</p>
			</div>
			<OurProcess />
		</section>
	);
}

const tenderManagement = {
	height: "40rem",
	widht: "auto",
	marginBottom: "10px",
};

const tenderManagementImg = {
	height: "100%",
	widht: "100%",
	objectFit: "contain",
};
