import './style-privacy.css';
import React, {useEffect} from 'react';
export default function Privacypolicy({ setSubFooter }) {
  useEffect(() => {
    window.scrollTo(0, 0);
    setSubFooter(false);
  }, []);
  return (
    <div className="">
      <div className="bg-img-privacy">
        <h3
          className="center-text h3-heading"
          style={{ margin: "30px 0", fontSize: "42px", color: "#fff" }}
        >
          Privacy policy
        </h3>
      </div>
      <section className="home4-service-section" style={{ paddingTop: "0px" }}>
        <div className="container">
          <div className="row ">
            <div
              className="head-section wdt-100 center-data"
              style={{ marginBottom: "20px" }}
            >
              <div className="col-lg-5 col-md-6 col-sm-4 col-xs-12">
                {/* <h3 className="center-text" style={{ marginBottom: "0px" }}>
                  Privacy <span className="clr-blue">policy </span>
                </h3> */}
              </div>
            </div>
            <div className="row text-center">
              <div className="storyPara">
                <p>
                  We, at Rawmart, take your privacy very seriously. We only take
                  the required information to deliver our services at high
                  quality and maintain that information in a secure way so that
                  no unauthorized third party can access your sensitive
                  information.
                </p>
                <p>
                  Read on our privacy policy to know more about what information
                  we collect and how we process the information to benefit you.
                </p>
                <p>
                  First, let us clarify terms. From here on, the terms “We” /
                  “Us” / “Our”/ “The Company” refers to RawMart and the terms
                  “You”/ “Your” refers to the users.
                </p>

                <p className="">
                  The privacy policy is a legally binding document and an
                  electronic record in an electronic contract form under the IT
                  Act 2000, the rules given here and the amended provisions of
                  the respective acts between you and us regarding the data we
                  receive from you and policy dictating its processing and
                  sharing.
                </p>
                <p>
                  By agreeing to this policy, you give us consent in collection,
                  storage, processing and transfer of the said data in
                  accordance with the described policy and all of its terms will
                  be effective upon the moment you accept or use this website.
                  Also, you agree that by collecting, storing, processing and
                  transferring your data there is no loss or any wrongful gain
                  by yourself or any other person.
                </p>
                <p>
                  Some of the core issues that SME's face these days include:
                </p>
                <ul>
                  <li>Lack of understanding of the right opportunities</li>
                  <li>
                    Uncertainty about the present and future market requirements
                  </li>
                  <li>Misaligned vision and strategy</li>
                  <li> Poor strategy execution and value realization</li>
                </ul>
               
                <p>
                  If you do not agree to this policy, please refrain from using
                  this website. As such, please read everything in this Privacy
                  policy and fully understand it.
                </p>
                <p>The Information we take from you:</p>
                <p>
                  We collect information from you while you are registering as a
                  user on our website. The information includes your name, age,
                  mobile number, sexual orientation, country, city and
                  address(es). Our service depends on this information and we
                  use it for delivering and improving our service.
                </p>
                <p>
                  We also take some information while you use our website
                  including your device whether its a personal computer or
                  mobile, the operating system, the browser you use to access
                  the website and the resolution. This helps us to improve the
                  performance and usability of the website across different
                  devices.
                </p>
                <p className="boldPara">Cookie policy:</p>
                <p>
                  While you use this website, we use “cookie” or any similar
                  software mechanism to uniquely identify users and to
                  personalize things. Unless you yourself provide your
                  identification information through registration, there is no
                  way for us to associate the cookie to you.
                </p>
                <p>
                  The Cookie might contain certain information and our website
                  retrieves it but in any way our website cannot read your
                  personal data stored in your disks. Also, our advertisers can
                  create cookies (if you interact with their advertisements) and
                  we do not control how that cookie works.
                </p>
                <p>
                  We also collect information regarding your IP Address (the
                  Address which uniquely identifies your computer on the
                  internet) and use this information to tailor our website
                  across geographies and also let our advertisers know from
                  where people are viewing their advertisements.
                </p>
                <p className="boldPara">Links to other websites:</p>
                <p>
                  At certain parts of our website, we may link other websites.
                  This privacy policy applies only to this website and once you
                  leave the website and open others, The terms would be
                  completely dictated by the website you visit and not us.
                </p>
                <p className="boldPara">Transfer of your information:</p>
                <p>
                  We may share your information to third parties without
                  obtaining your explicit approval in the following scenarios:
                </p>
                <p>
                  i) When the information is requested by law/ law agencies or
                  any governmental agencies for identity verification or in
                  prevention/investigation or prosecution for any criminal
                  activity. We provide this information only in good faith for
                  helping respective agencies in their activities and for
                  complying with the law of the land.
                </p>
                <p>
                  ii) We may also share the information with our external data
                  processors who would process the information on our behalf.
                  However, we ensure that they would work with the data
                  according to this privacy policy and our strict security
                  measures.
                </p>
                <p className="boldPara">How we secure your information:</p>
                <p>
                  We have an in-house penetration testing team that constantly
                  tries to attack our Grievance Resolution: systems and get the
                  data only to help us prevent such attacks from malicious
                  actors.
                </p>
                <p>
                  However hard we try, we cannot say for certain that our
                  systems are completely hack proof. We can only say that we try
                  our best to secure your information and always strive to
                  improve our processes.
                </p>
                <p>
                  This is an evolving policy and as such, we may change the
                  policy when it's needed. However, we would notify you
                  regarding the change and give you enough time to go through it
                  and make a decision whether to go on with the new terms or
                  not.
                </p>
                <p className="boldPara">Grievance Resolution:</p>
                <p>
                  We have a dedicated system for any grievance you have
                  including any comment/suggestion/report of breach you would
                  want to inform regarding this policy. You can write to us at:
                  <a href="mailto:service@rawmart.in">service@rawmart.in</a>{" "}
								<br />{" "}
								<a href="mailto:info.rawmart@gmail.com">
									info.rawmart@gmail.com
								</a>{" "}
                </p>
                <p>
                  Through reading this policy, we hope you got a fairly good
                  understanding of what information we take and how we use it.
                </p>
                {/* slider of logo */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}