import './style.css';

import React, { useState, useEffect, useRef } from "react";
import SubSections from './subSections';
import { Link, useLocation } from "react-router-dom";
const Consultation = ({ setSubFooter }) => {

  const location = useLocation();
  let submenu = useRef();
  // let [showMenu, setShowMenu] = useState(false);
  let [activeClass, setActiveClass] = useState("story");

  useEffect(() => {
    window.scrollTo(0, 0);
    let url = window.location.href.split("=")[1];
    setActiveClass(url ? url : "story");
    setSubFooter(false);
  }, [location]);

  

  function changeTab(e) {
    if (e.target.id !== "") setActiveClass(e.target.id);
  }

  return (
    <div className="">
      <div className="bg-img-consultation">
        <h2 className="headingH2">Our PSU Consulting</h2>
      </div>
      <section className="">
        <div className="container res-container ">
          <div className="">
            <ul
              className="listMenu bg-black"
              role="tablist"
              onClick={changeTab}
            >
              <li className={activeClass === "story" ? "active" : ""}>
                <Link className="" to="/consultation?tab=story" id="story">
                  Our Story
                </Link>
              </li>
              <li className={activeClass === "tmacTab" ? "active" : ""}>
                <Link className="" to="/consultation?tab=tmacTab" id="tmacTab">
                  Tender Management and Consulting
                </Link>
              </li>

              <li className={activeClass === "ourServices" ? "active " : ""}>
                <Link
                  className=""
                  to="/consultation?tab=ourServices"
                  id="ourServices"
                >
                  Our Services
                </Link>
              </li>
              <li
                style={{ position: "relative" }}
                className={
                  activeClass === "approch" ||
                  activeClass === "govOrg" ||
                  activeClass === "railway"
                    ? "active subMenu-1"
                    : "subMenu-1"
                }
              >
                <Link className="" to="/consultation?tab=approch">
                  PSU Companies
                </Link>
                <ul className="dropdown-submenu-1" ref={submenu}>
                  <li>
                    <Link
                      className=""
                      to="/consultation?tab=approch"
                      id="approch"
                    >
                      About PSU
                    </Link>
                  </li>
                  <li>
                    <Link
                      className=""
                      to="/consultation?tab=govOrg"
                      id="govOrg"
                    >
                      List of Government Organisations
                    </Link>
                  </li>
                  <li>
                    <Link
                      className=""
                      to="/consultation?tab=railway"
                      id="railway"
                    >
                      Indian Railway
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="mb-1">
            <SubSections tab={activeClass} />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Consultation;