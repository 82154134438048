import React, { useState } from "react";
import Info from "./info";
import MethodologiesSub from "./methodologies_sublist"
import MethodologiesSublistSecond from "./methodologies_sublist_second";
import ContactForm from "../common/contacrForm";
import './style.css'
import { useEffect } from "react";
import { Helmet } from 'react-helmet';

export default function Evolvement() {
    const meta_tag="Evolve"
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Evolvement";
        const metaKeywords = document.querySelector('meta[name="keywords"]');
		if (metaKeywords) {
		  metaKeywords.setAttribute('content', meta_tag);
		}
        const currentUrl = window.location.href;

        const metaTags = Array.from(document.getElementsByTagName('meta'));
    
        const ogUrlMeta = metaTags.find((meta) =>
          meta.getAttribute('property') === 'og:site_name'
        );
    
        if (ogUrlMeta) {
			ogUrlMeta.setAttribute("content",`${window.location.href}`);
        } else {
          const newOgUrlMeta = document.createElement('meta');
          newOgUrlMeta.setAttribute('property', 'og:site_name');
          newOgUrlMeta.content = currentUrl;
          document.head.appendChild(newOgUrlMeta);
        }
    //     const dynamicKeywords = 'Evolve'; // Provide the dynamic keywords here

    // const metaKeywords = document.querySelector('meta[name="keywords"]');
    // if (metaKeywords) {
    //   metaKeywords.setAttribute('content', "Evolve");
    // } else {
    //   const newMetaKeywords = document.createElement('meta');
    //   newMetaKeywords.setAttribute('name', 'keywords');
    //   newMetaKeywords.setAttribute('content', dynamicKeywords);
    //   document.head.appendChild(newMetaKeywords);
    // }
    }, [meta_tag]);
    let [activeClass, setActiveClass] = useState("sheet_metal");
    const CommonClass = "tab-pane hide";
    let data = {

        project_completed: {
            last: true,
            products: [
                {
                    productName: "Pharma Industry:",
                    para: [
                        `RAWMART has consistently delivered cutting-edge SPMs to clients in the pharmaceutical manufacturing sector. These customized machines have played a vital role in enhancing production efficiency, ensuring accurate dosage, and maintaining strict adherence to regulatory standards. RAWMART's expertise in this field has empowered pharmaceutical companies to optimize their manufacturing processes and meet industry-specific requirements.`,
                    ]
                },
                {
                    productName: "Steel Companies (ERW Pipe Manufacturing SPM):",
                    para: [
                        ` RAWMART has been instrumental in revolutionizing the manufacturing of Electric Resistance Welded (ERW) pipes in steel companies. By providing advanced SPMs specifically designed for this purpose, RAWMART has helped clients streamline their production processes, improve production speed, enhance quality control, and achieve consistent results. The unmatched support from RAWMART has made a significant impact on the steel industry.
                        `,
                    ]
                },
                {
                    productName: "FMCG Industry:",
                    para: [
                        ` RAWMART's extensive range of SPMs has catered to the unique needs of the Fast-Moving Consumer Goods (FMCG) industry. By offering automated solutions for packaging, labeling, filling, and other essential processes, RAWMART has enabled FMCG companies to boost productivity, enhance packaging quality, and stay ahead in a competitive market. RAWMART's unwavering support has been instrumental in empowering FMCG clients to meet their manufacturing goals effectively.
                        `,
                    ]
                },
                {
                    productName: "Wire Cutting and Wire Straightening:",
                    para: [
                        ` RAWMART's expertise extends to precision wire cutting and wire straightening operations. By delivering specialized SPMs, RAWMART has assisted clients in industries such as automotive, construction, and electrical, ensuring accurate dimensions, smooth surfaces, and efficient handling of wires. These tailored solutions have significantly contributed to improving productivity and overall operational efficiency for businesses relying on wire-based processes.`,
                    ]
                },
                {
                    productName: "Hydraulic:",
                    para: [
                        `Hydraulic below forming SPM.
                        `,
                    ]
                },
                {
                    productName: "Oil and Gas:",
                    para: [
                        `  Expansion Joints, Weldends, SS and MS Flanges (100mm to 3000 mm), Spool, Support Rings for pipe connectors, `
                    ],
                },

            ],

        },
        methodologies: {
            last: true,
            products: [
                {
                    productName: "Six Sigma:",
                    para: [
                        ` Rawmart employs Six Sigma to enhance quality, reduce costs, and improve customer satisfaction by eliminating process variability and defects.`,
                    ]
                },
                {
                    productName: "Zero Base Costing (ZBC):",
                    para: [
                        ` Rawmart ensures optimal expenses by justifying and optimizing every cost based on current business needs, driving efficiency and savings.`,
                    ]
                },
                {
                    productName: "Bulk Buying:",
                    para: [
                        `Rawmart leverages its purchasing power to obtain discounted prices through bulk buying, empowering SME's with cost advantages.`,
                    ]
                },
                {
                    productName: "PPAP: ",
                    para: [
                        ` Rawmart implements the stringent PPAP method to guarantee products meet customer requirements, minimizing defects before mass production.`,
                    ]
                },
                {
                    productName: "VAVE:",
                    para: [
                        `Rawmart employees VAVE to maximize the value of products and services by analyzing functions, reducing costs, improving quality, and enhancing performance. `,
                    ]
                },
                {
                    productName: "Oil and Gas:",
                    para: [
                        `  Expansion Joints, Weldends, SS and MS Flanges (100mm to 3000 mm), Spool, Support Rings for pipe connectors, `
                    ],
                },


            ],
        },
        methodologies_sublist: {
            last: true,
            products: [
                {
                    productName: "Market Intelligence:",
                    para: `Providing clients with insights for informed decisions on product development, pricing strategies, and marketing campaigns to stay competitive and anticipate customer needs.`,

                },
                {
                    productName: "Supplier Consolidation:",
                    para: `Streamlining suppliers by consolidating similar products or services, reducing complexity and improving supplier management.`
                },
                {
                    productName: "BOQ Optimization: ",
                    para: ` Optimizing the Bill of Quantities to lower costs while maintaining quality and performance standards.`
                },
                {
                    productName: "Inventory Management:",
                    para: `Efficiently managing inventory levels to ensure availability while minimizing excess and associated costs. Collaboration with suppliers enhances supply chain efficiency.`
                },
                {
                    productName: "Single Piece Flow:",
                    para: `Minimizing inventory through continuous product flow and reducing work-in-progress.
                    `,

                },
                {
                    productName: "Relay Out of Plant and Lines:",
                    para: ` Optimizing layouts to eliminate bottlenecks, enhancing productivity.
                    `
                },
                {
                    productName: "Outsourcing Spare Parts Management: ",
                    para: ` Efficiently managing critical components through specialized service providers.`
                },
                {
                    productName: "Improve ITR: ",
                    para: `Enhancing internal turnover ratio through concepts like runner, repetor, and stranger, optimizing inventory turnover and carrying costs.
                    `
                }
            ]
        },
        // methodologies_sublist_second: {
        //     last: true,
        //     products: [
        //         {
        //             productName:"Single Piece Flow:",
        //             para: `Minimizing inventory through continuous product flow and reducing work-in-progress.
        //             `,

        //         },
        //         {
        //             productName:"Relay Out of Plant and Lines:",
        //             para: ` Optimizing layouts to eliminate bottlenecks, enhancing productivity.
        //             `
        //         },
        //         {
        //             productName: "Outsourcing Spare Parts Management: ",
        //             para: ` Efficiently managing critical components through specialized service providers.`
        //         },
        //         {
        //             productName: "Improve ITR: ",
        //             para: `Enhancing Internal Turnover Ratio through concepts like runner, repetor, and stranger, optimizing inventory turnover and carrying costs.
        //             `
        //         }
        //     ]
        // },
        // impact_vision: {
        //     last: true,
        //     products: [
        //         {
        //             productName: "Enhanced Competitiveness:",
        //             para: `Rawmart empowers SME's to stay competitive through market intelligence and optimized processes.`,

        //         },
        //         {
        //             productName: "Improved Efficiency:",
        //             para: `Streamlining operations and optimizing resources with Rawmart's methodologies increases efficiency.
        //             `,

        //         },
        //         {
        //             productName: "Cost Savings: ",
        //             para: `Rawmart's strategies deliver substantial cost savings by eliminating waste and optimizing expenses.
        //             `,

        //         },
        //         {
        //             productName: "Quality Enhancement: ",
        //             para: `Adherence to Six Sigma and PPAP ensures high-quality products and services.
        //             `,

        //         },
        //         {
        //             productName: "Business Growth: ",
        //             para: `Rawmart's support enables SME's to expand, explore new markets, and scale their businesses.
        //             `,

        //         },
        //         {
        //             productName: "Empowering SME's:  ",
        //             para: `Ultimately, Rawmart's mission is to empower SME's across India, enabling their success and contributing to economic growth.`,

        //         },
        //     ]
        // }
    };

    function changeProducts(e) {
        setActiveClass(e.target.id);
    }

    return (
        <>
        <Helmet>
            <meta name="keywords" content={meta_tag}/>
            <meta property="og:title"  name="title" content="Evolvement" />

			</Helmet>
    
            {/* <Helmet>
                <title>Evolvement</title>
            </Helmet> */}
            <section className="home4 recent-project-section pad100-top projectsec1" >
                <section style={{ backgroundColor: "rgb(235, 233, 233)", marginTop: "-9px" }}>
                    <div className="container" >
                        <div className="row">
                            <h3
                                className="white-color h3Heading heading-h3"
                                style={{ marginBottom: "0px", paddingTop: "38px" }}
                            >
                                <span className="clr-blue">Projects Completed Till Date </span>
                            </h3>
                            <p
                                style={{
                                    margin: "22px 0px",
                                    fontSize: "17px",
                                    textAlign: "center",
                                    fontWeight: "bold",
                                    fontFamily: "'Nexa'!important"
                                }}
                            >
                                RAWMART has been a trusted provider of supported Special Purpose Machines (SPMs) for a diverse range of industries. With a proven track record of excellence, RAWMART has supported numerous clients in various projects involving SPMs tailored to specific needs. Here are some notable examples of RAWMART's successfull collaborations:

                            </p>
                            <div

                            >
                                <div style={{ display: "flex", justifyContent: "center", paddingTop: "20px", alignItems: "center", margin: "0 auto" }}>
                                    <img src="/assets/images/img/pxfuel_1.jpg" style={{ width: "100%", height: "100%" }} />
                                </div>
                                <Info info3={data["project_completed"]} />
                            </div>
                        </div>
                    </div>
                </section>
                <section >
                    <div className="container">
                        <div className="row">
                            <h3
                                className="white-color h3Heading heading-h3"
                                style={{ marginBottom: "0px", paddingTop: "38px" }}
                            >
                                <span className="clr-blue">Methodologies</span>
                            </h3>
                            <p
                                style={{
                                    margin: "22px 0px",
                                    fontSize: "17px",
                                    textAlign: "center",
                                    fontWeight: "bold",
                                    fontFamily: "'Nexa'!important"
                                }}
                            >
                                Rawmart is transforming small and medium-sized enterprises (SME's) through bellow strategies:


                            </p>
                            <div>
                                <div style={{ display: "flex", justifyContent: "center", paddingTop: "20px", alignItems: "center", margin: "0 auto" }}>
                                    <img src="/assets/images/img/methodlogies.jpg" style={{ width: "100%", height: "100%" }} />
                                </div>
                                <Info info3={data["methodologies"]} />
                                </div>
                                </div>
                                <div style={{
                                    
                                }}>
                                    <p style={{
                                        fontSize: "18px",
                                        fontFamily: "'Nexa'!important",
                                        textAlign: "center",
                                        backgroundColor:"#f2f2f2",borderRadius:"15px",boxShadow: "rgb(170, 170, 170) 2px 0px 6px"
                                       
                                    }}>With these principles, Rawmart transforms SME's, optimizing production, reducing expenses, ensuring quality, and driving business success day and night.
                                        Rawmart's dynamic approach, combining these techniques, has enabled significant growth for numerous SME's. With our continuous improvements and adaptability, our vision is to empower all SME's across India.</p>
                                </div>

                                    <div>
                                <p style={{
                                    marginTop: "22px",
                                    fontSize: "20px",
                                    fontWeight: "bold",
                                    fontFamily: "'Nexa'!important"
                                }}>
                                    How Rawmart is empowering Smes?
                                </p>
                                <MethodologiesSub info5={data["methodologies_sublist"]} />
                                {/* <MethodologiesSublistSecond info4={data["methodologies_sublist_second"]} /> */}
                                <p style={{
                                    margin: "22px 0px",
                                    fontSize: "15px",
                                    textAlign: "center",
                                    fontWeight: "bold",
                                    fontFamily: "'Nexa'!important"
                                }}>These methodologies empower businesses to transform operations, drive efficiency, and achieve sustainable growth.</p>
                            </div>
                            </div>
                       
                    </section>
                {/* <section style={{ backgroundColor: "rgb(235, 233, 233)", paddingTop: "inherit" }}>
                    <div className="container">
                        <div className="row">
                            <h3
                                className="white-color h3Heading heading-h3"
                                style={{ marginBottom: "0px" }}
                            >
                                <span className="clr-blue">Impact and Vision </span>
                            </h3>
                            <p
                                style={{
                                    margin: "22px 0px",
                                    fontSize: "20px",
                                    textAlign: "center",
                                    fontWeight: "bold",
                                    fontFamily: "'Nexa'!important"
                                }}
                            >
                                Rawmart's impact in the world of SME's is significant:

                            </p>
                            <div

                            >
                                <Info info3={data["project_completed"]} />
                            </div>
                        </div>
                    </div>
                </section> */}



                <section className="cutmClass bg-img-contactus">
                    <div className="container">
                        <ContactForm
                            title="Get a Quote"
                            subTitle="Fill up the form and our team will get back to you within 24 hours"
                            productSection={true}
                        />
                    </div>
                </section>

                {/* <div className="boxes-column" style={{ margin: 0 }}>
                    <ul>
                        <li style={{ width: "25%" }}>

                            <div
                                className="boxes-desc"
                                style={{
                                    fontFamily: "cursive",
                                    fontFamily: "Nexa",
                                    color: "gray",
                                    fontSize: "1rem",
                                }}
                            >
                                <h4>Contact Person</h4>
                                <p style={{ fontSize: "16px" }}>Vikrant Pardeshi</p>
                            </div>
                        </li>

                        <li style={{ width: "25%" }}>

                            <div className="boxes-desc">
                                <h4>Designation</h4>
                                <p style={{ fontSize: "16px", color: "#fff" }}>Sales Executive</p>
                            </div>
                        </li>

                        <li style={{ width: "25%" }}>

                            <div className="boxes-desc">
                                <h4>Phone No.</h4>
                                <a
                                    href="tel:+919309934491"
                                    target="_blank"
                                    style={{ color: "#fff" }}
                                >
                                    <p style={{ fontSize: "16px" }}> +91 9309934491</p>
                                </a>
                            </div>
                        </li>
                        <li style={{ width: "25%" }}>

                            <div className="boxes-desc">
                                <h4>Email</h4>
                                <a
                                    href="mailto:vikrant.Rawmart@gmail.com"
                                    target="_blank"
                                    style={{ color: "#fff" }}
                                >
                                    <p style={{ fontSize: "16px" }}>vikrant.rawmart@gmail.com</p>
                                </a>
                            </div>
                        </li>
                    </ul>
                </div> */}
            </section>
        </>
    );
}